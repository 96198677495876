import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
  ChatbotAdmin,
  ChatbotAllowedName,
  ChatbotBannedWord,
  ChatbotBlockedName,
  ChatbotPuzzle,
  ChatbotPuzzleHint,
  ChatbotRandomText,
  ChatbotSettings,
  ChatbotTextCommand,
  ChatbotWhoisDescription,
  CiccaToolzUser,
  TwitchClip,
  TwitchStream,
  TwitchStreamParticipantResponse,
  TwitchStreamParticipantSession,
} from "../models";
import authService from "../components/api-authorization/AuthorizeService";
import moment from "moment";
import {
  ChatbotAdminPayload,
  ChatbotAllowedNamePayload,
  ChatbotBannedWordPayload,
  ChatbotBlockedNamePayload,
  ChatbotPuzzleHintPayload,
  ChatbotPuzzlePayload,
  ChatbotRandomTextPayload,
  ChatbotSettingsPayload,
  ChatbotTextCommandPayload,
  ChatbotWhoisDescriptionPayload,
} from "../payloads";
import TwitchStreamUser from "../models/TwitchStreamUser";

export interface CiccaToolzState {
  loggedInUser: CiccaToolzUser | null;
  clips: TwitchClip[];
  streams: TwitchStream[];
  streamParticipants: TwitchStreamParticipantResponse | null;
  streamParticipantSessions: TwitchStreamParticipantSession[];
  streamUsers: TwitchStreamUser[];
  chatbotSettings: ChatbotSettings;
  chatbotRandomTexts: ChatbotRandomText[];
  chatbotTextCommands: ChatbotTextCommand[];
  chatbotAllowedNames: ChatbotAllowedName[];
  chatbotBlockedNames: ChatbotBlockedName[];
  chatbotAdmins: ChatbotAdmin[];
  chatbotBannedWords: ChatbotBannedWord[];
  chatbotPuzzles: ChatbotPuzzle[];
  chatbotPuzzleHints: ChatbotPuzzleHint[];
  chatbotWhoisDescriptions: ChatbotWhoisDescription[];
  shutdownInitiated: boolean;
  loading: number;
}

export interface GetUserRequestAction {
  type: "GET_USER_REQUEST";
}

export interface GetUserResponseAction {
  type: "GET_USER_RESPONSE";
  result: CiccaToolzUser | null;
}

export interface GetClipsRequestAction {
  type: "GET_CLIPS_REQUEST";
}
export interface GetClipsResponseAction {
  type: "GET_CLIPS_RESPONSE";
  result: TwitchClip[];
}

export interface GetStreamsRequestAction {
  type: "GET_STREAMS_REQUEST";
}
export interface GetStreamsResponseAction {
  type: "GET_STREAMS_RESPONSE";
  result: TwitchStream[];
}

export interface GetStreamParticipantsRequestAction {
  type: "GET_STREAM_PARTICIPANTS_REQUEST";
}
export interface GetStreamParticipantsResponseAction {
  type: "GET_STREAM_PARTICIPANTS_RESPONSE";
  result: TwitchStreamParticipantResponse | null;
}

export interface GetStreamParticipantSessionsRequestAction {
  type: "GET_STREAM_PARTICIPANT_SESSIONS_REQUEST";
}
export interface GetStreamParticipantSessionsResponseAction {
  type: "GET_STREAM_PARTICIPANT_SESSIONS_RESPONSE";
  result: TwitchStreamParticipantSession[];
}

export interface ClearStreamParticipantSessionsAction {
  type: "CLEAR_STREAM_PARTICIPANT_SESSIONS";
}

export interface GetStreamUsersRequestAction {
  type: "GET_STREAM_USERS_REQUEST";
}
export interface GetStreamUsersResponseAction {
  type: "GET_STREAM_USERS_RESPONSE";
  result: TwitchStreamUser[];
}

export interface ClearStreamUsersAction {
  type: "CLEAR_STREAM_USERS";
}

export interface GetChatbotSettingsRequestAction {
  type: "GET_CHATBOT_SETTINGS_REQUEST";
}
export interface GetChatbotSettingsResponseAction {
  type: "GET_CHATBOT_SETTINGS_RESPONSE";
  result: ChatbotSettings;
}

export interface UpdateChatbotSettingsRequestAction {
  type: "UPDATE_CHATBOT_SETTINGS_REQUEST";
}
export interface UpdateChatbotSettingsResponseAction {
  type: "UPDATE_CHATBOT_SETTINGS_RESPONSE";
  result: ChatbotSettings;
}

export interface GetChatbotRandomTextsRequestAction {
  type: "GET_CHATBOT_RANDOM_TEXTS_REQUEST";
}
export interface GetChatbotRandomTextsResponseAction {
  type: "GET_CHATBOT_RANDOM_TEXTS_RESPONSE";
  result: ChatbotRandomText[];
}

export interface CreateChatbotRandomTextRequestAction {
  type: "CREATE_CHATBOT_RANDOM_TEXT_REQUEST";
}
export interface CreateChatbotRandomTextResponseAction {
  type: "CREATE_CHATBOT_RANDOM_TEXT_RESPONSE";
  result: ChatbotRandomText | null;
}

export interface UpdateChatbotRandomTextRequestAction {
  type: "UPDATE_CHATBOT_RANDOM_TEXT_REQUEST";
}
export interface UpdateChatbotRandomTextResponseAction {
  type: "UPDATE_CHATBOT_RANDOM_TEXT_RESPONSE";
  result: ChatbotRandomText | null;
}

export interface DeleteChatbotRandomTextRequestAction {
  type: "DELETE_CHATBOT_RANDOM_TEXT_REQUEST";
}
export interface DeleteChatbotRandomTextResponseAction {
  type: "DELETE_CHATBOT_RANDOM_TEXT_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotTextCommandsRequestAction {
  type: "GET_CHATBOT_TEXT_COMMANDS_REQUEST";
}
export interface GetChatbotTextCommandsResponseAction {
  type: "GET_CHATBOT_TEXT_COMMANDS_RESPONSE";
  result: ChatbotTextCommand[];
}

export interface CreateChatbotTextCommandRequestAction {
  type: "CREATE_CHATBOT_TEXT_COMMAND_REQUEST";
}
export interface CreateChatbotTextCommandResponseAction {
  type: "CREATE_CHATBOT_TEXT_COMMAND_RESPONSE";
  result: ChatbotTextCommand | null;
}

export interface UpdateChatbotTextCommandRequestAction {
  type: "UPDATE_CHATBOT_TEXT_COMMAND_REQUEST";
}
export interface UpdateChatbotTextCommandResponseAction {
  type: "UPDATE_CHATBOT_TEXT_COMMAND_RESPONSE";
  result: ChatbotTextCommand | null;
}

export interface DeleteChatbotTextCommandRequestAction {
  type: "DELETE_CHATBOT_TEXT_COMMAND_REQUEST";
}
export interface DeleteChatbotTextCommandResponseAction {
  type: "DELETE_CHATBOT_TEXT_COMMAND_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotAllowedNamesRequestAction {
  type: "GET_CHATBOT_ALLOWED_NAMES_REQUEST";
}
export interface GetChatbotAllowedNamesResponseAction {
  type: "GET_CHATBOT_ALLOWED_NAMES_RESPONSE";
  result: ChatbotAllowedName[];
}

export interface CreateChatbotAllowedNameRequestAction {
  type: "CREATE_CHATBOT_ALLOWED_NAME_REQUEST";
}
export interface CreateChatbotAllowedNameResponseAction {
  type: "CREATE_CHATBOT_ALLOWED_NAME_RESPONSE";
  result: ChatbotAllowedName | null;
}

export interface UpdateChatbotAllowedNameRequestAction {
  type: "UPDATE_CHATBOT_ALLOWED_NAME_REQUEST";
}
export interface UpdateChatbotAllowedNameResponseAction {
  type: "UPDATE_CHATBOT_ALLOWED_NAME_RESPONSE";
  result: ChatbotAllowedName | null;
}

export interface DeleteChatbotAllowedNameRequestAction {
  type: "DELETE_CHATBOT_ALLOWED_NAME_REQUEST";
}
export interface DeleteChatbotAllowedNameResponseAction {
  type: "DELETE_CHATBOT_ALLOWED_NAME_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotBlockedNamesRequestAction {
  type: "GET_CHATBOT_BLOCKED_NAMES_REQUEST";
}
export interface GetChatbotBlockedNamesResponseAction {
  type: "GET_CHATBOT_BLOCKED_NAMES_RESPONSE";
  result: ChatbotBlockedName[];
}

export interface CreateChatbotBlockedNameRequestAction {
  type: "CREATE_CHATBOT_BLOCKED_NAME_REQUEST";
}
export interface CreateChatbotBlockedNameResponseAction {
  type: "CREATE_CHATBOT_BLOCKED_NAME_RESPONSE";
  result: ChatbotBlockedName | null;
}

export interface UpdateChatbotBlockedNameRequestAction {
  type: "UPDATE_CHATBOT_BLOCKED_NAME_REQUEST";
}
export interface UpdateChatbotBlockedNameResponseAction {
  type: "UPDATE_CHATBOT_BLOCKED_NAME_RESPONSE";
  result: ChatbotBlockedName | null;
}

export interface DeleteChatbotBlockedNameRequestAction {
  type: "DELETE_CHATBOT_BLOCKED_NAME_REQUEST";
}
export interface DeleteChatbotBlockedNameResponseAction {
  type: "DELETE_CHATBOT_BLOCKED_NAME_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotAdminsRequestAction {
  type: "GET_CHATBOT_ADMINS_REQUEST";
}
export interface GetChatbotAdminsResponseAction {
  type: "GET_CHATBOT_ADMINS_RESPONSE";
  result: ChatbotAdmin[];
}

export interface CreateChatbotAdminRequestAction {
  type: "CREATE_CHATBOT_ADMIN_REQUEST";
}
export interface CreateChatbotAdminResponseAction {
  type: "CREATE_CHATBOT_ADMIN_RESPONSE";
  result: ChatbotAdmin | null;
}

export interface UpdateChatbotAdminRequestAction {
  type: "UPDATE_CHATBOT_ADMIN_REQUEST";
}
export interface UpdateChatbotAdminResponseAction {
  type: "UPDATE_CHATBOT_ADMIN_RESPONSE";
  result: ChatbotAdmin | null;
}

export interface DeleteChatbotAdminRequestAction {
  type: "DELETE_CHATBOT_ADMIN_REQUEST";
}
export interface DeleteChatbotAdminResponseAction {
  type: "DELETE_CHATBOT_ADMIN_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotBannedWordsRequestAction {
  type: "GET_CHATBOT_BANNED_WORDS_REQUEST";
}
export interface GetChatbotBannedWordsResponseAction {
  type: "GET_CHATBOT_BANNED_WORDS_RESPONSE";
  result: ChatbotBannedWord[];
}

export interface CreateChatbotBannedWordRequestAction {
  type: "CREATE_CHATBOT_BANNED_WORD_REQUEST";
}
export interface CreateChatbotBannedWordResponseAction {
  type: "CREATE_CHATBOT_BANNED_WORD_RESPONSE";
  result: ChatbotBannedWord | null;
}

export interface UpdateChatbotBannedWordRequestAction {
  type: "UPDATE_CHATBOT_BANNED_WORD_REQUEST";
}
export interface UpdateChatbotBannedWordResponseAction {
  type: "UPDATE_CHATBOT_BANNED_WORD_RESPONSE";
  result: ChatbotBannedWord | null;
}

export interface DeleteChatbotBannedWordRequestAction {
  type: "DELETE_CHATBOT_BANNED_WORD_REQUEST";
}
export interface DeleteChatbotBannedWordResponseAction {
  type: "DELETE_CHATBOT_BANNED_WORD_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotPuzzlesRequestAction {
  type: "GET_CHATBOT_PUZZLES_REQUEST";
}
export interface GetChatbotPuzzlesResponseAction {
  type: "GET_CHATBOT_PUZZLES_RESPONSE";
  result: ChatbotPuzzle[];
}

export interface CreateChatbotPuzzleRequestAction {
  type: "CREATE_CHATBOT_PUZZLE_REQUEST";
}
export interface CreateChatbotPuzzleResponseAction {
  type: "CREATE_CHATBOT_PUZZLE_RESPONSE";
  result: ChatbotPuzzle | null;
}

export interface UpdateChatbotPuzzleRequestAction {
  type: "UPDATE_CHATBOT_PUZZLE_REQUEST";
}
export interface UpdateChatbotPuzzleResponseAction {
  type: "UPDATE_CHATBOT_PUZZLE_RESPONSE";
  result: ChatbotPuzzle | null;
}

export interface DeleteChatbotPuzzleRequestAction {
  type: "DELETE_CHATBOT_PUZZLE_REQUEST";
}
export interface DeleteChatbotPuzzleResponseAction {
  type: "DELETE_CHATBOT_PUZZLE_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatbotPuzzleHintsRequestAction {
  type: "GET_CHATBOT_PUZZLE_HINTS_REQUEST";
}
export interface GetChatbotPuzzleHintsResponseAction {
  type: "GET_CHATBOT_PUZZLE_HINTS_RESPONSE";
  result: ChatbotPuzzleHint[];
}

export interface CreateChatbotPuzzleHintRequestAction {
  type: "CREATE_CHATBOT_PUZZLE_HINT_REQUEST";
}
export interface CreateChatbotPuzzleHintResponseAction {
  type: "CREATE_CHATBOT_PUZZLE_HINT_RESPONSE";
  result: ChatbotPuzzleHint | null;
}

export interface UpdateChatbotPuzzleHintRequestAction {
  type: "UPDATE_CHATBOT_PUZZLE_HINT_REQUEST";
}
export interface UpdateChatbotPuzzleHintResponseAction {
  type: "UPDATE_CHATBOT_PUZZLE_HINT_RESPONSE";
  result: ChatbotPuzzleHint | null;
}

export interface DeleteChatbotPuzzleHintRequestAction {
  type: "DELETE_CHATBOT_PUZZLE_HINT_REQUEST";
}
export interface DeleteChatbotPuzzleHintResponseAction {
  type: "DELETE_CHATBOT_PUZZLE_HINT_RESPONSE";
  result: boolean;
  id: number;
}

export interface SendChatbotMessageRequestAction {
  type: "SEND_CHATBOT_MESSAGE_REQUEST";
}
export interface SendChatbotMessageResponseAction {
  type: "SEND_CHATBOT_MESSAGE_RESPONSE";
}

export interface ForceShutdownRequestAction {
  type: "FORCE_SHUTDOWN_REQUEST";
}
export interface ForceShutdownResponseAction {
  type: "FORCE_SHUTDOWN_RESPONSE";
}

export interface ResetTreasureHuntRequestAction {
  type: "RESET_TREASURE_HUNT_REQUEST";
}
export interface ResetTreasureHuntResponseAction {
  type: "RESET_TREASURE_HUNT_RESPONSE";
}

export interface GetChatbotWhoisDescriptionsRequestAction {
  type: "GET_CHATBOT_WHOIS_DESCRIPTIONS_REQUEST";
}
export interface GetChatbotWhoisDescriptionsResponseAction {
  type: "GET_CHATBOT_WHOIS_DESCRIPTIONS_RESPONSE";
  result: ChatbotWhoisDescription[];
}

export interface CreateChatbotWhoisDescriptionRequestAction {
  type: "CREATE_CHATBOT_WHOIS_DESCRIPTION_REQUEST";
}
export interface CreateChatbotWhoisDescriptionResponseAction {
  type: "CREATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE";
  result: ChatbotWhoisDescription | null;
}

export interface UpdateChatbotWhoisDescriptionRequestAction {
  type: "UPDATE_CHATBOT_WHOIS_DESCRIPTION_REQUEST";
}
export interface UpdateChatbotWhoisDescriptionResponseAction {
  type: "UPDATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE";
  result: ChatbotWhoisDescription | null;
}

export interface DeleteChatbotWhoisDescriptionRequestAction {
  type: "DELETE_CHATBOT_WHOIS_DESCRIPTION_REQUEST";
}
export interface DeleteChatbotWhoisDescriptionResponseAction {
  type: "DELETE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE";
  result: boolean;
  id: number;
}

export interface GetChatMessageExportByStreamRequestAction {
  type: "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_REQUEST";
}
export interface GetChatMessageExportByStreamResponseAction {
  type: "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_RESPONSE";
}

export interface GetChatMessageExportByUsernameRequestAction {
  type: "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_REQUEST";
}
export interface GetChatMessageExportByUsernameResponseAction {
  type: "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_RESPONSE";
}

export interface ErrorAction {
  type: "ERROR";
}

export type KnownAction =
  | GetUserRequestAction
  | GetUserResponseAction
  | GetClipsRequestAction
  | GetClipsResponseAction
  | GetStreamsRequestAction
  | GetStreamsResponseAction
  | GetStreamParticipantsRequestAction
  | GetStreamParticipantsResponseAction
  | GetStreamParticipantSessionsRequestAction
  | GetStreamParticipantSessionsResponseAction
  | ClearStreamParticipantSessionsAction
  | GetStreamUsersRequestAction
  | GetStreamUsersResponseAction
  | ClearStreamUsersAction
  | GetChatbotSettingsRequestAction
  | GetChatbotSettingsResponseAction
  | UpdateChatbotSettingsRequestAction
  | UpdateChatbotSettingsResponseAction
  | GetChatbotRandomTextsRequestAction
  | GetChatbotRandomTextsResponseAction
  | CreateChatbotRandomTextRequestAction
  | CreateChatbotRandomTextResponseAction
  | UpdateChatbotRandomTextRequestAction
  | UpdateChatbotRandomTextResponseAction
  | DeleteChatbotRandomTextRequestAction
  | DeleteChatbotRandomTextResponseAction
  | GetChatbotTextCommandsRequestAction
  | GetChatbotTextCommandsResponseAction
  | CreateChatbotTextCommandRequestAction
  | CreateChatbotTextCommandResponseAction
  | UpdateChatbotTextCommandRequestAction
  | UpdateChatbotTextCommandResponseAction
  | DeleteChatbotTextCommandRequestAction
  | DeleteChatbotTextCommandResponseAction
  | GetChatbotAllowedNamesRequestAction
  | GetChatbotAllowedNamesResponseAction
  | CreateChatbotAllowedNameRequestAction
  | CreateChatbotAllowedNameResponseAction
  | UpdateChatbotAllowedNameRequestAction
  | UpdateChatbotAllowedNameResponseAction
  | DeleteChatbotAllowedNameRequestAction
  | DeleteChatbotAllowedNameResponseAction
  | GetChatbotBlockedNamesRequestAction
  | GetChatbotBlockedNamesResponseAction
  | CreateChatbotBlockedNameRequestAction
  | CreateChatbotBlockedNameResponseAction
  | UpdateChatbotBlockedNameRequestAction
  | UpdateChatbotBlockedNameResponseAction
  | DeleteChatbotBlockedNameRequestAction
  | DeleteChatbotBlockedNameResponseAction
  | GetChatbotAdminsRequestAction
  | GetChatbotAdminsResponseAction
  | CreateChatbotAdminRequestAction
  | CreateChatbotAdminResponseAction
  | UpdateChatbotAdminRequestAction
  | UpdateChatbotAdminResponseAction
  | DeleteChatbotAdminRequestAction
  | DeleteChatbotAdminResponseAction
  | GetChatbotBannedWordsRequestAction
  | GetChatbotBannedWordsResponseAction
  | CreateChatbotBannedWordRequestAction
  | CreateChatbotBannedWordResponseAction
  | UpdateChatbotBannedWordRequestAction
  | UpdateChatbotBannedWordResponseAction
  | DeleteChatbotBannedWordRequestAction
  | DeleteChatbotBannedWordResponseAction
  | GetChatbotPuzzlesRequestAction
  | GetChatbotPuzzlesResponseAction
  | CreateChatbotPuzzleRequestAction
  | CreateChatbotPuzzleResponseAction
  | UpdateChatbotPuzzleRequestAction
  | UpdateChatbotPuzzleResponseAction
  | DeleteChatbotPuzzleRequestAction
  | DeleteChatbotPuzzleResponseAction
  | GetChatbotPuzzleHintsRequestAction
  | GetChatbotPuzzleHintsResponseAction
  | CreateChatbotPuzzleHintRequestAction
  | CreateChatbotPuzzleHintResponseAction
  | UpdateChatbotPuzzleHintRequestAction
  | UpdateChatbotPuzzleHintResponseAction
  | DeleteChatbotPuzzleHintRequestAction
  | DeleteChatbotPuzzleHintResponseAction
  | SendChatbotMessageRequestAction
  | SendChatbotMessageResponseAction
  | ForceShutdownRequestAction
  | ForceShutdownResponseAction
  | ResetTreasureHuntRequestAction
  | ResetTreasureHuntResponseAction
  | GetChatbotWhoisDescriptionsRequestAction
  | GetChatbotWhoisDescriptionsResponseAction
  | CreateChatbotWhoisDescriptionRequestAction
  | CreateChatbotWhoisDescriptionResponseAction
  | UpdateChatbotWhoisDescriptionRequestAction
  | UpdateChatbotWhoisDescriptionResponseAction
  | DeleteChatbotWhoisDescriptionRequestAction
  | DeleteChatbotWhoisDescriptionResponseAction
  | GetChatMessageExportByStreamRequestAction
  | GetChatMessageExportByStreamResponseAction
  | GetChatMessageExportByUsernameRequestAction
  | GetChatMessageExportByUsernameResponseAction
  | ErrorAction;

export const actionCreators = {
  getLoggedInUser:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/auth", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<CiccaToolzUser>)
        .then((data) => {
          dispatch({ type: "GET_USER_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({ type: "GET_USER_RESPONSE", result: null });
        });

      dispatch({ type: "GET_USER_REQUEST" });
    },
  getClips: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const token = await authService.getAccessToken();
    fetch("api/twitch/clips", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((response) => response.json() as Promise<TwitchClip[]>)
      .then((data) => {
        dispatch({ type: "GET_CLIPS_RESPONSE", result: data });
      })
      .catch((e) => {
        dispatch({ type: "GET_CLIPS_RESPONSE", result: [] as TwitchClip[] });
      });

    dispatch({ type: "GET_CLIPS_REQUEST" });
  },
  getStreams: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const token = await authService.getAccessToken();
    fetch("api/twitch/streams", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((response) => response.json() as Promise<TwitchStream[]>)
      .then((data) => {
        dispatch({ type: "GET_STREAMS_RESPONSE", result: data });
      })
      .catch((e) => {
        dispatch({
          type: "GET_STREAMS_RESPONSE",
          result: [] as TwitchStream[],
        });
      });

    dispatch({ type: "GET_STREAMS_REQUEST" });
  },
  getStreamParticipants:
    (streamId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/twitch/streams/${streamId}/participants`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then(
          (response) =>
            response.json() as Promise<TwitchStreamParticipantResponse>
        )
        .then((data) => {
          dispatch({ type: "GET_STREAM_PARTICIPANTS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({ type: "GET_STREAM_PARTICIPANTS_RESPONSE", result: null });
        });

      dispatch({ type: "GET_STREAM_PARTICIPANTS_REQUEST" });
    },
  getStreamParticipantSessions:
    (streamId: number, participantId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(
        `api/twitch/streams/${streamId}/participants/${participantId}/sessions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      )
        .then(
          (response) =>
            response.json() as Promise<TwitchStreamParticipantSession[]>
        )
        .then((data) => {
          dispatch({
            type: "GET_STREAM_PARTICIPANT_SESSIONS_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_STREAM_PARTICIPANT_SESSIONS_RESPONSE",
            result: [] as TwitchStreamParticipantSession[],
          });
        });

      dispatch({ type: "GET_STREAM_PARTICIPANT_SESSIONS_REQUEST" });
    },
  clearStreamParticipantSessions: () =>
    ({
      type: "CLEAR_STREAM_PARTICIPANT_SESSIONS",
    } as ClearStreamParticipantSessionsAction),
  getStreamUsers:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/twitch/stream-users", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<TwitchStreamUser[]>)
        .then((data) => {
          dispatch({
            type: "GET_STREAM_USERS_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_STREAM_USERS_RESPONSE",
            result: [] as TwitchStreamUser[],
          });
        });

      dispatch({ type: "GET_STREAM_PARTICIPANT_SESSIONS_REQUEST" });
    },
  clearStreamUsers: () =>
    ({
      type: "CLEAR_STREAM_USERS",
    } as ClearStreamUsersAction),
  getChatbotSettings:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/settings", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotSettings>)
        .then((data) => {
          dispatch({ type: "GET_CHATBOT_SETTINGS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_SETTINGS_RESPONSE",
            result: getState().ciccaToolz!.chatbotSettings,
          });
        });

      dispatch({ type: "GET_CHATBOT_SETTINGS_REQUEST" });
    },
  updateChatbotSettings:
    (chatbotSettings: ChatbotSettingsPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/settings", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotSettings),
      })
        .then((response) => response.json() as Promise<ChatbotSettings>)
        .then((data) => {
          dispatch({ type: "UPDATE_CHATBOT_SETTINGS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_SETTINGS_RESPONSE",
            result: getState().ciccaToolz!.chatbotSettings,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_SETTINGS_REQUEST" });
    },
  getChatbotRandomTexts:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/random-texts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotRandomText[]>)
        .then((data) => {
          dispatch({ type: "GET_CHATBOT_RANDOM_TEXTS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_RANDOM_TEXTS_RESPONSE",
            result: getState().ciccaToolz!.chatbotRandomTexts,
          });
        });

      dispatch({ type: "GET_CHATBOT_RANDOM_TEXTS_REQUEST" });
    },
  createChatbotRandomText:
    (
      chatbotRandomText: ChatbotRandomTextPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/random-texts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotRandomText),
      })
        .then((response) => response.json() as Promise<ChatbotRandomText>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_RANDOM_TEXT_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_RANDOM_TEXT_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_RANDOM_TEXT_REQUEST" });
    },
  updateChatbotRandomText:
    (
      id: number,
      chatbotRandomText: ChatbotRandomTextPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/random-texts/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotRandomText),
      })
        .then((response) => response.json() as Promise<ChatbotRandomText>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_RANDOM_TEXT_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_RANDOM_TEXT_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_RANDOM_TEXT_REQUEST" });
    },
  deleteChatbotRandomText:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/random-texts/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_RANDOM_TEXT_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_RANDOM_TEXT_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_RANDOM_TEXT_REQUEST" });
    },
  getChatbotTextCommands:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/text-commands", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotTextCommand[]>)
        .then((data) => {
          dispatch({
            type: "GET_CHATBOT_TEXT_COMMANDS_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_TEXT_COMMANDS_RESPONSE",
            result: getState().ciccaToolz!.chatbotTextCommands,
          });
        });

      dispatch({ type: "GET_CHATBOT_TEXT_COMMANDS_REQUEST" });
    },
  createChatbotTextCommand:
    (
      chatbotTextCommand: ChatbotTextCommandPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/text-commands", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotTextCommand),
      })
        .then((response) => response.json() as Promise<ChatbotTextCommand>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_TEXT_COMMAND_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_TEXT_COMMAND_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_TEXT_COMMAND_REQUEST" });
    },
  updateChatbotTextCommand:
    (
      id: number,
      chatbotTextCommand: ChatbotTextCommandPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/text-commands/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotTextCommand),
      })
        .then((response) => response.json() as Promise<ChatbotTextCommand>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_TEXT_COMMAND_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_TEXT_COMMAND_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_TEXT_COMMAND_REQUEST" });
    },
  deleteChatbotTextCommand:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/text-commands/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_TEXT_COMMAND_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_TEXT_COMMAND_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_TEXT_COMMAND_REQUEST" });
    },
  getChatbotAllowedNames:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/allowed-names", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotAllowedName[]>)
        .then((data) => {
          dispatch({
            type: "GET_CHATBOT_ALLOWED_NAMES_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_ALLOWED_NAMES_RESPONSE",
            result: getState().ciccaToolz!.chatbotAllowedNames,
          });
        });

      dispatch({ type: "GET_CHATBOT_ALLOWED_NAMES_REQUEST" });
    },
  createChatbotAllowedName:
    (
      chatbotAllowedName: ChatbotAllowedNamePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/allowed-names", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotAllowedName),
      })
        .then((response) => response.json() as Promise<ChatbotAllowedName>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_ALLOWED_NAME_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_ALLOWED_NAME_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_ALLOWED_NAME_REQUEST" });
    },
  updateChatbotAllowedName:
    (
      id: number,
      chatbotAllowedName: ChatbotAllowedNamePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/allowed-names/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotAllowedName),
      })
        .then((response) => response.json() as Promise<ChatbotAllowedName>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_ALLOWED_NAME_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_ALLOWED_NAME_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_ALLOWED_NAME_REQUEST" });
    },
  deleteChatbotAllowedName:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/allowed-names/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_ALLOWED_NAME_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_ALLOWED_NAME_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_ALLOWED_NAME_REQUEST" });
    },
  getChatbotBlockedNames:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/blocked-names", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotBlockedName[]>)
        .then((data) => {
          dispatch({
            type: "GET_CHATBOT_BLOCKED_NAMES_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_BLOCKED_NAMES_RESPONSE",
            result: getState().ciccaToolz!.chatbotBlockedNames,
          });
        });

      dispatch({ type: "GET_CHATBOT_BLOCKED_NAMES_REQUEST" });
    },
  createChatbotBlockedName:
    (
      chatbotBlockedName: ChatbotBlockedNamePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/blocked-names", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotBlockedName),
      })
        .then((response) => response.json() as Promise<ChatbotBlockedName>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_BLOCKED_NAME_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_BLOCKED_NAME_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_BLOCKED_NAME_REQUEST" });
    },
  updateChatbotBlockedName:
    (
      id: number,
      chatbotBlockedName: ChatbotBlockedNamePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/blocked-names/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotBlockedName),
      })
        .then((response) => response.json() as Promise<ChatbotBlockedName>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_BLOCKED_NAME_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_BLOCKED_NAME_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_BLOCKED_NAME_REQUEST" });
    },
  deleteChatbotBlockedName:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/blocked-names/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_BLOCKED_NAME_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_BLOCKED_NAME_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_BLOCKED_NAME_REQUEST" });
    },
  getChatbotAdmins:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/admins", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotAdmin[]>)
        .then((data) => {
          dispatch({ type: "GET_CHATBOT_ADMINS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_ADMINS_RESPONSE",
            result: getState().ciccaToolz!.chatbotAdmins,
          });
        });

      dispatch({ type: "GET_CHATBOT_ADMINS_REQUEST" });
    },
  createChatbotAdmin:
    (chatbotAdmin: ChatbotAdminPayload): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/admins", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotAdmin),
      })
        .then((response) => response.json() as Promise<ChatbotAdmin>)
        .then((data) => {
          dispatch({ type: "CREATE_CHATBOT_ADMIN_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({ type: "CREATE_CHATBOT_ADMIN_RESPONSE", result: null });
        });

      dispatch({ type: "CREATE_CHATBOT_ADMIN_REQUEST" });
    },
  updateChatbotAdmin:
    (
      id: number,
      chatbotAdmin: ChatbotAdminPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/admins/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotAdmin),
      })
        .then((response) => response.json() as Promise<ChatbotAdmin>)
        .then((data) => {
          dispatch({ type: "UPDATE_CHATBOT_ADMIN_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({ type: "UPDATE_CHATBOT_ADMIN_RESPONSE", result: null });
        });

      dispatch({ type: "UPDATE_CHATBOT_ADMIN_REQUEST" });
    },
  deleteChatbotAdmin:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/admins/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_ADMIN_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_ADMIN_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_ADMIN_REQUEST" });
    },
  getChatbotBannedWords:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/banned-words", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotBannedWord[]>)
        .then((data) => {
          dispatch({ type: "GET_CHATBOT_BANNED_WORDS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_BANNED_WORDS_RESPONSE",
            result: getState().ciccaToolz!.chatbotBannedWords,
          });
        });

      dispatch({ type: "GET_CHATBOT_BANNED_WORDS_REQUEST" });
    },
  createChatbotBannedWord:
    (
      chatbotBannedWord: ChatbotBannedWordPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/banned-words", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotBannedWord),
      })
        .then((response) => response.json() as Promise<ChatbotBannedWord>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_BANNED_WORD_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_BANNED_WORD_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_BANNED_WORD_REQUEST" });
    },
  updateChatbotBannedWord:
    (
      id: number,
      chatbotBannedWord: ChatbotBannedWordPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/banned-words/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotBannedWord),
      })
        .then((response) => response.json() as Promise<ChatbotBannedWord>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_BANNED_WORD_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_BANNED_WORD_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_BANNED_WORD_REQUEST" });
    },
  deleteChatbotBannedWord:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/banned-words/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_BANNED_WORD_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_BANNED_WORD_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_BANNED_WORD_REQUEST" });
    },
  sendChatbotMessage:
    (message: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/message", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(message),
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({ type: "SEND_CHATBOT_MESSAGE_RESPONSE" });
        })
        .catch((e) => {
          dispatch({ type: "SEND_CHATBOT_MESSAGE_RESPONSE" });
        });

      dispatch({ type: "SEND_CHATBOT_MESSAGE_REQUEST" });
    },
  forceShutdown:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/shutdown", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({ type: "FORCE_SHUTDOWN_RESPONSE" });
        })
        .catch((e) => {
          dispatch({ type: "FORCE_SHUTDOWN_RESPONSE" });
        });

      dispatch({ type: "FORCE_SHUTDOWN_REQUEST" });
    },
  getChatbotPuzzles:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/puzzles", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotPuzzle[]>)
        .then((data) => {
          dispatch({ type: "GET_CHATBOT_PUZZLES_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_PUZZLES_RESPONSE",
            result: getState().ciccaToolz!.chatbotPuzzles,
          });
        });

      dispatch({ type: "GET_CHATBOT_PUZZLES_REQUEST" });
    },
  createChatbotPuzzle:
    (chatbotPuzzle: ChatbotPuzzlePayload): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/puzzles", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotPuzzle),
      })
        .then((response) => response.json() as Promise<ChatbotPuzzle>)
        .then((data) => {
          dispatch({ type: "CREATE_CHATBOT_PUZZLE_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({ type: "CREATE_CHATBOT_PUZZLE_RESPONSE", result: null });
        });

      dispatch({ type: "CREATE_CHATBOT_PUZZLE_REQUEST" });
    },
  updateChatbotPuzzle:
    (
      id: number,
      chatbotPuzzle: ChatbotPuzzlePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/puzzles/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotPuzzle),
      })
        .then((response) => response.json() as Promise<ChatbotPuzzle>)
        .then((data) => {
          dispatch({ type: "UPDATE_CHATBOT_PUZZLE_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({ type: "UPDATE_CHATBOT_PUZZLE_RESPONSE", result: null });
        });

      dispatch({ type: "UPDATE_CHATBOT_PUZZLE_REQUEST" });
    },
  deleteChatbotPuzzle:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/puzzles/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_PUZZLE_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_PUZZLE_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_PUZZLE_REQUEST" });
    },
  getChatbotPuzzleHints:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/puzzle-hints", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => response.json() as Promise<ChatbotPuzzleHint[]>)
        .then((data) => {
          dispatch({ type: "GET_CHATBOT_PUZZLE_HINTS_RESPONSE", result: data });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_PUZZLE_HINTS_RESPONSE",
            result: getState().ciccaToolz!.chatbotPuzzleHints,
          });
        });

      dispatch({ type: "GET_CHATBOT_PUZZLE_HINTS_REQUEST" });
    },
  createChatbotPuzzleHint:
    (
      chatbotPuzzleHint: ChatbotPuzzleHintPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/puzzle-hints", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotPuzzleHint),
      })
        .then((response) => response.json() as Promise<ChatbotPuzzleHint>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_PUZZLE_HINT_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_PUZZLE_HINT_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_PUZZLE_HINT_REQUEST" });
    },
  updateChatbotPuzzleHint:
    (
      id: number,
      chatbotPuzzleHint: ChatbotPuzzleHintPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/puzzle-hints/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotPuzzleHint),
      })
        .then((response) => response.json() as Promise<ChatbotPuzzleHint>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_PUZZLE_HINT_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_PUZZLE_HINT_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_PUZZLE_HINT_REQUEST" });
    },
  deleteChatbotPuzzleHint:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/puzzle-hints/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_PUZZLE_HINT_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_PUZZLE_HINT_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_PUZZLE_HINT_REQUEST" });
    },
  resetTreasureHunt:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/puzzles/reset", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({ type: "RESET_TREASURE_HUNT_RESPONSE" });
        })
        .catch((e) => {
          dispatch({ type: "RESET_TREASURE_HUNT_RESPONSE" });
        });

      dispatch({ type: "RESET_TREASURE_HUNT_REQUEST" });
    },
  getChatbotWhoisDescriptions:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/whois-descriptions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then(
          (response) => response.json() as Promise<ChatbotWhoisDescription[]>
        )
        .then((data) => {
          dispatch({
            type: "GET_CHATBOT_WHOIS_DESCRIPTIONS_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHATBOT_WHOIS_DESCRIPTIONS_RESPONSE",
            result: getState().ciccaToolz!.chatbotWhoisDescriptions,
          });
        });

      dispatch({ type: "GET_CHATBOT_WHOIS_DESCRIPTIONS_REQUEST" });
    },
  createChatbotWhoisDescription:
    (
      chatbotWhoisDescription: ChatbotWhoisDescriptionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const token = await authService.getAccessToken();
      fetch("api/chatbot/whois-descriptions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: JSON.stringify(chatbotWhoisDescription),
      })
        .then((response) => response.json() as Promise<ChatbotWhoisDescription>)
        .then((data) => {
          dispatch({
            type: "CREATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "CREATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "CREATE_CHATBOT_WHOIS_DESCRIPTION_REQUEST" });
    },
  updateChatbotWhoisDescription:
    (
      id: number,
      chatbotWhoisDescription: ChatbotWhoisDescriptionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/whois-descriptions/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(chatbotWhoisDescription),
      })
        .then((response) => response.json() as Promise<ChatbotWhoisDescription>)
        .then((data) => {
          dispatch({
            type: "UPDATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE",
            result: data,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE",
            result: null,
          });
        });

      dispatch({ type: "UPDATE_CHATBOT_WHOIS_DESCRIPTION_REQUEST" });
    },
  deleteChatbotWhoisDescription:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/chatbot/whois-descriptions/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })
        .then((response) => response.json() as Promise<boolean>)
        .then((data) => {
          dispatch({
            type: "DELETE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE",
            result: data,
            id: id,
          });
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE",
            result: false,
            id: id,
          });
        });

      dispatch({ type: "DELETE_CHATBOT_WHOIS_DESCRIPTION_REQUEST" });
    },
  getChatMessageExportByStream:
    (streamId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(`api/twitch/streams/${streamId}/chat-export`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((response) => {
          const filename = getFilenameFromResponse(
            response,
            `Twitch_Chat_Export_Stream_${streamId}.txt`
          );

          return {
            data: response.blob(),
            filename: filename,
          };
        })
        .then((data) => {
          downloadBlob(data.data, data.filename);
          dispatch({
            type: "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_RESPONSE",
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_RESPONSE",
          });
        });

      dispatch({ type: "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_REQUEST" });
    },
  getChatMessageExportByUsername:
    (username: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const token = await authService.getAccessToken();
      fetch(
        `api/twitch/participants/chat-export?username=${encodeURIComponent(
          username
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      )
        .then((response) => {
          const filename = getFilenameFromResponse(
            response,
            `Twitch_Chat_Export_User_${username}.txt`
          );

          return {
            data: response.blob(),
            filename: filename,
          };
        })
        .then((data) => {
          downloadBlob(data.data, data.filename);
          dispatch({
            type: "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_RESPONSE",
          });
        })
        .catch((e) => {
          dispatch({
            type: "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_RESPONSE",
          });
        });

      dispatch({ type: "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_REQUEST" });
    },
  error: () => ({ type: "ERROR" } as ErrorAction),
};

export const reducer: Reducer<CiccaToolzState> = (
  state: CiccaToolzState | undefined,
  incomingAction: Action
): CiccaToolzState => {
  if (state === undefined) {
    return {
      loggedInUser: null,
      clips: [],
      streams: [],
      streamParticipants: null,
      streamParticipantSessions: [],
      streamUsers: [],
      chatbotSettings: {
        enabled: false,
        autoStartEnabled: false,
        mockOnline: false,
        puzzlesEnabled: false,
        timeoutOrBanBroadcaster: false,
        timeoutOrBanModerators: false,
        timeoutOrBanVips: false,
      },
      chatbotRandomTexts: [],
      chatbotTextCommands: [],
      chatbotAllowedNames: [],
      chatbotBlockedNames: [],
      chatbotAdmins: [],
      chatbotBannedWords: [],
      chatbotPuzzles: [],
      chatbotPuzzleHints: [],
      chatbotWhoisDescriptions: [],
      shutdownInitiated: false,
      loading: 0,
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_USER_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        loggedInUser: null,
      };
    case "GET_USER_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        loggedInUser: action.result,
      };
    case "GET_CLIPS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        streams: [],
      };
    case "GET_CLIPS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        clips: action.result.map((x) => {
          return {
            ...x,
            createdAt: moment(x.createdAt).toDate(),
          };
        }),
      };
    case "GET_STREAMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        streams: [],
      };
    case "GET_STREAMS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        streams: action.result.map((x) => {
          return {
            ...x,
            startedAt: new Date(x.startedAt),
            endedAt: x.endedAt == null ? null : new Date(x.endedAt),
          };
        }),
      };
    case "GET_STREAM_PARTICIPANTS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        streamParticipants: null,
      };
    case "GET_STREAM_PARTICIPANTS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        streamParticipants: action.result,
      };
    case "GET_STREAM_PARTICIPANT_SESSIONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        streamParticipantSessions: [],
      };
    case "GET_STREAM_PARTICIPANT_SESSIONS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        streamParticipantSessions: action.result.map((x) => {
          return {
            ...x,
            startedAt: new Date(x.startedAt),
            endedAt: x.endedAt == null ? null : new Date(x.endedAt),
          };
        }),
      };
    case "CLEAR_STREAM_PARTICIPANT_SESSIONS":
      return {
        ...state,
        streamParticipantSessions: [],
      };
    case "GET_STREAM_USERS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        streamUsers: [],
      };
    case "GET_STREAM_USERS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        streamUsers: action.result,
      };
    case "CLEAR_STREAM_USERS":
      return {
        ...state,
        streamUsers: [],
      };
    case "GET_CHATBOT_SETTINGS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "GET_CHATBOT_SETTINGS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotSettings: action.result,
      };
    case "UPDATE_CHATBOT_SETTINGS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_SETTINGS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotSettings: action.result,
      };
    case "GET_CHATBOT_RANDOM_TEXTS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotRandomTexts: [],
      };
    case "GET_CHATBOT_RANDOM_TEXTS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotRandomTexts: action.result,
      };
    case "CREATE_CHATBOT_RANDOM_TEXT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_RANDOM_TEXT_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotRandomTexts:
          action.result == null
            ? state.chatbotRandomTexts
            : state.chatbotRandomTexts
                .concat(action.result)
                .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0)),
      };
    case "UPDATE_CHATBOT_RANDOM_TEXT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_RANDOM_TEXT_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotRandomTexts:
          action.result == null
            ? state.chatbotRandomTexts
            : state.chatbotRandomTexts
                .map((randomText) => {
                  return randomText.id === action.result!.id
                    ? action.result!
                    : randomText;
                })
                .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0)),
      };
    case "DELETE_CHATBOT_RANDOM_TEXT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_RANDOM_TEXT_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotRandomTexts: action.result
          ? state.chatbotRandomTexts
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0))
          : state.chatbotRandomTexts,
      };
    case "GET_CHATBOT_TEXT_COMMANDS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotTextCommands: [],
      };
    case "GET_CHATBOT_TEXT_COMMANDS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotTextCommands: action.result,
      };
    case "CREATE_CHATBOT_TEXT_COMMAND_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_TEXT_COMMAND_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotTextCommands:
          action.result == null
            ? state.chatbotTextCommands
            : state.chatbotTextCommands
                .concat(action.result)
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "UPDATE_CHATBOT_TEXT_COMMAND_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_TEXT_COMMAND_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotTextCommands:
          action.result == null
            ? state.chatbotTextCommands
            : state.chatbotTextCommands
                .map((textCommand) => {
                  return textCommand.id === action.result!.id
                    ? action.result!
                    : textCommand;
                })
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "DELETE_CHATBOT_TEXT_COMMAND_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_TEXT_COMMAND_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotTextCommands: action.result
          ? state.chatbotTextCommands
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          : state.chatbotTextCommands,
      };
    case "GET_CHATBOT_ALLOWED_NAMES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotAllowedNames: [],
      };
    case "GET_CHATBOT_ALLOWED_NAMES_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAllowedNames: action.result,
      };
    case "CREATE_CHATBOT_ALLOWED_NAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_ALLOWED_NAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAllowedNames:
          action.result == null
            ? state.chatbotAllowedNames
            : state.chatbotAllowedNames
                .concat(action.result)
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "UPDATE_CHATBOT_ALLOWED_NAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_ALLOWED_NAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAllowedNames:
          action.result == null
            ? state.chatbotAllowedNames
            : state.chatbotAllowedNames
                .map((textCommand) => {
                  return textCommand.id === action.result!.id
                    ? action.result!
                    : textCommand;
                })
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "DELETE_CHATBOT_ALLOWED_NAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_ALLOWED_NAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAllowedNames: action.result
          ? state.chatbotAllowedNames
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          : state.chatbotAllowedNames,
      };
    case "GET_CHATBOT_BLOCKED_NAMES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotBlockedNames: [],
      };
    case "GET_CHATBOT_BLOCKED_NAMES_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBlockedNames: action.result,
      };
    case "CREATE_CHATBOT_BLOCKED_NAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_BLOCKED_NAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBlockedNames:
          action.result == null
            ? state.chatbotBlockedNames
            : state.chatbotBlockedNames
                .concat(action.result)
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "UPDATE_CHATBOT_BLOCKED_NAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_BLOCKED_NAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBlockedNames:
          action.result == null
            ? state.chatbotBlockedNames
            : state.chatbotBlockedNames
                .map((textCommand) => {
                  return textCommand.id === action.result!.id
                    ? action.result!
                    : textCommand;
                })
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "DELETE_CHATBOT_BLOCKED_NAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_BLOCKED_NAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBlockedNames: action.result
          ? state.chatbotBlockedNames
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          : state.chatbotBlockedNames,
      };
    case "GET_CHATBOT_ADMINS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotAdmins: [],
      };
    case "GET_CHATBOT_ADMINS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAdmins: action.result,
      };
    case "CREATE_CHATBOT_ADMIN_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_ADMIN_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAdmins:
          action.result == null
            ? state.chatbotAdmins
            : state.chatbotAdmins
                .concat(action.result)
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "UPDATE_CHATBOT_ADMIN_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_ADMIN_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAdmins:
          action.result == null
            ? state.chatbotAdmins
            : state.chatbotAdmins
                .map((textCommand) => {
                  return textCommand.id === action.result!.id
                    ? action.result!
                    : textCommand;
                })
                .sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
      };
    case "DELETE_CHATBOT_ADMIN_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_ADMIN_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotAdmins: action.result
          ? state.chatbotAdmins
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          : state.chatbotAdmins,
      };
    case "GET_CHATBOT_BANNED_WORDS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotBannedWords: [],
      };
    case "GET_CHATBOT_BANNED_WORDS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBannedWords: action.result,
      };
    case "CREATE_CHATBOT_BANNED_WORD_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_BANNED_WORD_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBannedWords:
          action.result == null
            ? state.chatbotBannedWords
            : state.chatbotBannedWords
                .concat(action.result)
                .sort((a, b) =>
                  a.word > b.word ? 1 : a.word < b.word ? -1 : 0
                ),
      };
    case "UPDATE_CHATBOT_BANNED_WORD_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_BANNED_WORD_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBannedWords:
          action.result == null
            ? state.chatbotBannedWords
            : state.chatbotBannedWords
                .map((textCommand) => {
                  return textCommand.id === action.result!.id
                    ? action.result!
                    : textCommand;
                })
                .sort((a, b) =>
                  a.word > b.word ? 1 : a.word < b.word ? -1 : 0
                ),
      };
    case "DELETE_CHATBOT_BANNED_WORD_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_BANNED_WORD_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotBannedWords: action.result
          ? state.chatbotBannedWords
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.word > b.word ? 1 : a.word < b.word ? -1 : 0))
          : state.chatbotBannedWords,
      };
    case "SEND_CHATBOT_MESSAGE_REQUEST":
      return {
        ...state,
      };
    case "SEND_CHATBOT_MESSAGE_RESPONSE":
      return {
        ...state,
      };
    case "FORCE_SHUTDOWN_REQUEST":
      return {
        ...state,
        shutdownInitiated: false,
        loading: state.loading + 1,
      };
    case "FORCE_SHUTDOWN_RESPONSE":
      return {
        ...state,
        shutdownInitiated: true,
        loading: state.loading - 1,
      };
    case "GET_CHATBOT_PUZZLES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotPuzzles: [],
      };
    case "GET_CHATBOT_PUZZLES_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzles: action.result,
      };
    case "CREATE_CHATBOT_PUZZLE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_PUZZLE_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzles:
          action.result == null
            ? state.chatbotPuzzles
            : state.chatbotPuzzles
                .concat(action.result)
                .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0)),
      };
    case "UPDATE_CHATBOT_PUZZLE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_PUZZLE_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzles:
          action.result == null
            ? state.chatbotPuzzles
            : state.chatbotPuzzles
                .map((puzzle) => {
                  return puzzle.id === action.result!.id
                    ? action.result!
                    : puzzle;
                })
                .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0)),
      };
    case "DELETE_CHATBOT_PUZZLE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_PUZZLE_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzles: action.result
          ? state.chatbotPuzzles
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
          : state.chatbotPuzzles,
      };
    case "GET_CHATBOT_PUZZLE_HINTS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotPuzzleHints: [],
      };
    case "GET_CHATBOT_PUZZLE_HINTS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzleHints: action.result,
      };
    case "CREATE_CHATBOT_PUZZLE_HINT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_PUZZLE_HINT_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzleHints:
          action.result == null
            ? state.chatbotPuzzleHints
            : state.chatbotPuzzleHints
                .concat(action.result)
                .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0)),
      };
    case "UPDATE_CHATBOT_PUZZLE_HINT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_PUZZLE_HINT_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzleHints:
          action.result == null
            ? state.chatbotPuzzleHints
            : state.chatbotPuzzleHints
                .map((puzzleHint) => {
                  return puzzleHint.id === action.result!.id
                    ? action.result!
                    : puzzleHint;
                })
                .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0)),
      };
    case "DELETE_CHATBOT_PUZZLE_HINT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_PUZZLE_HINT_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotPuzzleHints: action.result
          ? state.chatbotPuzzleHints
              .filter((x) => x.id !== action.id)
              .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
          : state.chatbotPuzzleHints,
      };
    case "GET_CHATBOT_WHOIS_DESCRIPTIONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        chatbotWhoisDescriptions: [],
      };
    case "GET_CHATBOT_WHOIS_DESCRIPTIONS_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotWhoisDescriptions: action.result,
      };
    case "CREATE_CHATBOT_WHOIS_DESCRIPTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "CREATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotWhoisDescriptions:
          action.result == null
            ? state.chatbotWhoisDescriptions
            : [action.result].concat(state.chatbotWhoisDescriptions),
      };
    case "UPDATE_CHATBOT_WHOIS_DESCRIPTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotWhoisDescriptions:
          action.result == null
            ? state.chatbotWhoisDescriptions
            : state.chatbotWhoisDescriptions.map((x) => {
                return x.id === action.result!.id ? action.result! : x;
              }),
      };
    case "DELETE_CHATBOT_WHOIS_DESCRIPTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "DELETE_CHATBOT_WHOIS_DESCRIPTION_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        chatbotWhoisDescriptions: action.result
          ? state.chatbotWhoisDescriptions.filter((x) => x.id !== action.id)
          : state.chatbotWhoisDescriptions,
      };
    case "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "GET_CHAT_MESSAGE_EXPORT_BY_STREAM_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
      };
    case "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "GET_CHAT_MESSAGE_EXPORT_BY_USERNAME_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
      };
    case "ERROR":
      return { ...state, loading: state.loading - 1 };
    default:
      return state;
  }
};

const getFilenameFromResponse = (
  response: Response,
  defaultFilename: string
) => {
  let filename = defaultFilename;
  if (response.headers && response.headers.get("content-disposition")) {
    filename =
      (response.headers.get("content-disposition") as string)
        ?.split(";")
        ?.map((x) => x.trim())
        ?.find((x) => x.startsWith("filename="))
        ?.substring(9) || defaultFilename;
  }

  return filename;
};

const downloadBlob = (documentBlob: Promise<Blob>, filename: string) => {
  documentBlob.then((data) => {
    const documentObjectUrl = URL.createObjectURL(data);
    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.href = documentObjectUrl;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  });
};
