import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store/index";
import * as CiccaToolzStore from "../store/CiccaToolzStore";
import { TwitchClip } from "../models";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  Button,
} from "reactstrap";
import { ClipCard } from "./ClipCard";
import moment from "moment";
import "moment/locale/hu";

type ClipsPropsType = CiccaToolzStore.CiccaToolzState &
  typeof CiccaToolzStore.actionCreators &
  RouteComponentProps<{}>;

interface ClipAuthor {
  username: string;
  clipCount: number;
}

interface Category {
  name: string;
  clipCount: number;
}

enum Sort {
  Date,
  Views,
}

interface ClipsState {
  selectedUser: ClipAuthor | null;
  selectedCategory: Category | null;
  search: string;
  userDropdownOpen: boolean;
  categoryDropdownOpen: boolean;
  users: ClipAuthor[];
  categories: Category[];
  filteredClips: TwitchClip[];
  sort: Sort;
}

class Clips extends React.PureComponent<ClipsPropsType, ClipsState> {
  constructor(props: ClipsPropsType) {
    super(props);

    this.state = {
      selectedUser: null,
      selectedCategory: null,
      search: "",
      userDropdownOpen: false,
      categoryDropdownOpen: false,
      users: [],
      categories: [],
      filteredClips: this.sortClips(props.clips),
      sort: Sort.Date,
    };
  }

  componentWillMount() {
    this.setDropdownValues(this.props.clips);
    this.props.getClips();
  }

  componentWillReceiveProps(nextProps: ClipsPropsType) {
    if (nextProps.clips.length !== this.props.clips.length) {
      this.setDropdownValues(nextProps.clips);
    }
  }

  public render() {
    return (
      <React.Fragment>
        <div className={`loader ${this.props.loading ? "show" : ""}`}>
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mb-3">
              Klipek ({this.state.filteredClips.length} /{" "}
              {this.props.clips.length})
            </h4>
          </div>
          <div className="d-flex align-items-end">
            <div className="form-group mr-3 search-input-container">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder="Keresés címben..."
                value={this.state.search}
                onChange={this.handleSearchChange}
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="mr-2">Rendezés:</div>
              <ButtonGroup className="mr-3 align-items-center">
                <Button
                  color="primary"
                  outline
                  onClick={() => this.handleSortChange(Sort.Date)}
                  active={this.state.sort === Sort.Date}
                >
                  Dátum
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={() => this.handleSortChange(Sort.Views)}
                  active={this.state.sort === Sort.Views}
                >
                  Nézettség
                </Button>
              </ButtonGroup>
            </div>

            <Dropdown
              isOpen={this.state.userDropdownOpen}
              toggle={this.toggleUserDropdown}
              className="clip-filter-dropdown mr-3"
            >
              <DropdownToggle
                caret
                className="w-100 d-flex justify-content-between align-items-center"
              >
                {this.state.selectedUser == null
                  ? "Készítő"
                  : `${this.state.selectedUser.username} (${this.state.selectedUser.clipCount})`}
              </DropdownToggle>
              <DropdownMenu className="clip-filter-dropdown-item-container">
                <DropdownItem
                  onClick={() => this.handleSelectedUserChange(null)}
                >
                  * Bárki *
                </DropdownItem>
                {this.state.users.map((user, index) => {
                  return (
                    <React.Fragment key={`user-dropdown-item-${index}`}>
                      <DropdownItem
                        onClick={() => this.handleSelectedUserChange(user)}
                      >
                        {user.username} ({user.clipCount})
                      </DropdownItem>
                    </React.Fragment>
                  );
                })}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={this.state.categoryDropdownOpen}
              toggle={this.toggleCategoryDropdown}
              className="clip-filter-dropdown"
            >
              <DropdownToggle
                caret
                className="w-100 d-flex justify-content-between align-items-center"
              >
                {this.state.selectedCategory == null
                  ? "Kategória"
                  : `${this.state.selectedCategory.name} (${this.state.selectedCategory.clipCount})`}
              </DropdownToggle>
              <DropdownMenu className="clip-filter-dropdown-item-container">
                <DropdownItem
                  onClick={() => this.handleSelectedCategoryChange(null)}
                >
                  * Bármilyen kategória *
                </DropdownItem>
                {this.state.categories.map((category, index) => {
                  return (
                    <React.Fragment key={`category-dropdown-item-${index}`}>
                      <DropdownItem
                        onClick={() =>
                          this.handleSelectedCategoryChange(category)
                        }
                      >
                        {category.name} ({category.clipCount})
                      </DropdownItem>
                    </React.Fragment>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <div className="mb-4">
          {this.state.filteredClips.length > 0 && (
            <React.Fragment>
              <div className="d-flex align-content-start flex-wrap">
                {this.state.filteredClips.map((clip, index) => {
                  return (
                    <React.Fragment key={`clip-${index}`}>
                      <ClipCard clip={clip} />
                    </React.Fragment>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
        {!this.props.loading && this.state.filteredClips.length === 0 && (
          <h6>Nem található klip.</h6>
        )}
      </React.Fragment>
    );
  }

  private handleSortChange = (sort: Sort) => {
    this.setState(
      {
        sort: sort,
      },
      () => this.filterClips()
    );
  };

  private setDropdownValues = (clips: TwitchClip[]) => {
    const clipGroups: ClipAuthor[] = [];
    const categoryGroups: Category[] = [];

    for (const clip of clips) {
      const clipGroup = clipGroups.find(
        (x) => x.username === clip.curatorDisplayName
      );
      if (clipGroup == null) {
        clipGroups.push({
          username: clip.curatorDisplayName,
          clipCount: 1,
        });
      } else {
        clipGroup.clipCount++;
      }

      const clipCategory = categoryGroups.find((x) => x.name === clip.category);
      if (clipCategory == null) {
        categoryGroups.push({
          name: clip.category,
          clipCount: 1,
        });
      } else {
        clipCategory.clipCount++;
      }
    }

    clipGroups.sort((a, b) => {
      const aName = a.username.toLowerCase();
      const bName = b.username.toLowerCase();

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    });

    categoryGroups.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    });

    this.setState(
      {
        users: clipGroups,
        categories: categoryGroups,
      },
      () => this.filterClips()
    );
  };

  private handleSearchChange = (e: any) => {
    this.setState(
      {
        search: e.target.value,
      },
      () => this.filterClips()
    );
  };

  private handleSelectedUserChange = (user: ClipAuthor | null) => {
    this.setState(
      {
        selectedUser: user,
      },
      () => this.filterClips()
    );
  };

  private handleSelectedCategoryChange = (category: Category | null) => {
    this.setState(
      {
        selectedCategory: category,
      },
      () => this.filterClips()
    );
  };

  private sortClips = (clips: TwitchClip[]): TwitchClip[] => {
    return clips.slice().sort((a, b) => {
      return this.state.sort === Sort.Date
        ? moment(a.createdAt).isBefore(moment(b.createdAt))
          ? 1
          : -1
        : a.views < b.views
        ? 1
        : -1;
    });
  };

  private filterClips = () => {
    const filteringNeeded =
      (this.state.search != null && this.state.search.length > 0) ||
      this.state.selectedUser != null ||
      this.state.selectedCategory != null;

    if (!filteringNeeded) {
      this.setState({
        filteredClips: this.sortClips(this.props.clips),
      });
    } else {
      const filteredClips = this.props.clips.filter((clip) => {
        if (this.state.search != null && this.state.search.length > 0) {
          if (
            clip.title
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase().trim()) === -1
          ) {
            return false;
          }
        }

        if (this.state.selectedUser != null) {
          if (clip.curatorDisplayName !== this.state.selectedUser.username) {
            return false;
          }
        }

        if (this.state.selectedCategory != null) {
          if (clip.category !== this.state.selectedCategory.name) {
            return false;
          }
        }

        return true;
      });

      this.setState({
        filteredClips: this.sortClips(filteredClips),
      });
    }
  };

  private toggleUserDropdown = () => {
    this.setState({
      userDropdownOpen: !this.state.userDropdownOpen,
    });
  };

  private toggleCategoryDropdown = () => {
    this.setState({
      categoryDropdownOpen: !this.state.categoryDropdownOpen,
    });
  };
}

export default connect(
  (state: ApplicationState) => state.ciccaToolz,
  CiccaToolzStore.actionCreators
)(Clips as any);
