import * as CiccaToolzStore from "./CiccaToolzStore";

export interface ApplicationState {
    ciccaToolz: CiccaToolzStore.CiccaToolzState | undefined;
}

export const reducers = {
    ciccaToolz: CiccaToolzStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
