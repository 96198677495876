import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ChatbotBannedWord } from "../models";
import { ChatbotBannedWordPayload } from "../payloads";

interface ChatbotBannedWordsProps {
    loading: number;
    items: ChatbotBannedWord[];
    create: (payload: ChatbotBannedWordPayload) => void;
    update: (id: number, payload: ChatbotBannedWordPayload) => void;
    delete: (id: number) => void;
}

interface ChatbotBannedWordsState {
    word: string;
    ban: boolean;
    isCaseSensitive: boolean;
    isAccentSensitive: boolean;
    countThresholdRaw: string;
    countThreshold: number;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
}

export class ChatbotBannedWords extends React.PureComponent<ChatbotBannedWordsProps, ChatbotBannedWordsState> {
    constructor(props: ChatbotBannedWordsProps) {
        super(props);

        this.state = {
            word: "",
            ban: false,
            isCaseSensitive: false,
            isAccentSensitive: true,
            countThresholdRaw: "1",
            countThreshold: 1,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: []
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                    <Button onClick={this.handleCreateClick} color="primary">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új tiltott szó
                    </Button>
                </div>
                {this.props.items.length == null && <h2 className="center">Nem található tiltott szó.</h2>}
                <ul className="list-group">
                    {this.props.items.map((item, index) => {
                        return (
                            <li className="list-group-item d-flex justify-content-between align-items-center" key={`text-command-list-item-${index}`}>
                                <div><FontAwesomeIcon icon={["fas", "ban"]} className="fa-fw mr-1" /> {item.word}</div>
                                <div>
                                    <Button className="mr-2" color="primary" onClick={() => this.handleEditClick(index)} data-toggle="tooltip" data-placement="bottom" title="Szerkesztés">
                                        <FontAwesomeIcon id={`chatbot-text-command-edit-icon-${index}`} icon={["fas", "pencil-alt"]} className="fa-fw" />
                                    </Button>
                                    <Button className="mr-2" color="danger" onClick={() => this.handleDeleteClick(index)} data-toggle="tooltip" data-placement="bottom" title="Törlés">
                                        <FontAwesomeIcon id={`chatbot-text-command-delete-icon-${index}`} icon={["fas", "trash-alt"]} className="fa-fw" />
                                    </Button>
                                </div>
                            </li>
                        );
                    })}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
            </React.Fragment>
        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.items.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeEditModal}>Tiltott szó {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputChatbotBannedWordWord">Szó</label>
                        <input type="text" className="form-control" id="inputChatbotBannedWordWord" autoComplete="off" placeholder="Szó" value={this.state.word} onChange={this.handleWordChange} />
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputChatbotBannedWordBan" checked={this.state.ban} onChange={this.handleBanChange} />
                        <label className="form-check-label" htmlFor="inputChatbotBannedWordBan">Ban</label>
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputChatbotBannedWordIsCaseSensitive" checked={this.state.isCaseSensitive} onChange={this.handleIsCaseSensitiveChange} />
                        <label className="form-check-label" htmlFor="inputChatbotBannedWordIsCaseSensitive">Kis-nagybetű érzékeny</label>
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputChatbotBannedWordIsAccentSensitive" checked={this.state.isAccentSensitive} onChange={this.handleIsAccentSensitiveChange} />
                        <label className="form-check-label" htmlFor="inputChatbotBannedWordIsAccentSensitive">Ékezetérzékeny</label>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputChatbotBannedWordCountThreshold">Küszöbérték streamenként</label>
                        <input type="text" className="form-control" id="inputChatbotBannedWordCountThreshold" autoComplete="off" placeholder="Küszöbérték streamenként" value={this.state.countThresholdRaw} onChange={this.handleCountThresholdChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`client-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading && this.props.items.length > this.state.deleteIndex} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeDeleteModal}>Tiltott szó törlése</ModalHeader>
                <ModalBody>
                    {!isNaN(this.state.deleteIndex) &&
                        <div>
                            Biztosan törölni szeretnéd a(z) <strong>{this.props.items[this.state.deleteIndex].word}</strong> tiltott szót?
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleDelete} type="button" className="btn btn-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleCountThresholdChange = (e: any) => {
        const raw = e.target.value;
        const targetValue = parseInt(raw.replace(/\D/, ""));
        if (!isNaN(targetValue)) {
            this.setState({
                countThreshold: targetValue,
                countThresholdRaw: raw
            }, () => { this.validate(true); });
        }
    }

    private handleDelete = () => {
        this.props.delete(this.props.items[this.state.deleteIndex].id);

        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleWordChange = (e: any) => {
        this.setState({
            word: e.target.value
        }, () => { this.validate(true); });
    }

    private handleBanChange = (e: any) => {
        this.setState({
            ban: e.target.checked
        });
    }

    private handleIsCaseSensitiveChange = (e: any) => {
        this.setState({
            isCaseSensitive: e.target.checked
        });
    }

    private handleIsAccentSensitiveChange = (e: any) => {
        this.setState({
            isAccentSensitive: e.target.checked
        });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleDeleteClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private handleCreateClick = () => {
        this.resetForm();

        this.setState({
            editIndex: -1
        });
    }

    private handleEditClick = (index: number) => {
        this.resetForm();

        const item = this.props.items[index];

        this.setState({
            word: item.word,
            ban: item.ban,
            isCaseSensitive: item.isCaseSensitive,
            isAccentSensitive: item.isAccentSensitive,
            countThresholdRaw: item.countThreshold.toString(),
            countThreshold: item.countThreshold,
            editIndex: index
        });
    }

    private handleSaveClick = () => {
        if (this.state.editIndex === -1) {
            this.props.create({
                word: this.state.word,
                ban: this.state.ban,
                isCaseSensitive: this.state.isCaseSensitive,
                isAccentSensitive: this.state.isAccentSensitive,
                countThreshold: this.state.countThreshold
            });
        } else {
            this.props.update(this.props.items[this.state.editIndex].id, {
                word: this.state.word,
                ban: this.state.ban,
                isCaseSensitive: this.state.isCaseSensitive,
                isAccentSensitive: this.state.isAccentSensitive,
                countThreshold: this.state.countThreshold
            });
        }

        this.closeEditModal();
        this.resetForm();
    }

    private resetForm = () => {
        this.setState({
            word: "",
            ban: false,
            isCaseSensitive: false,
            isAccentSensitive: true
        }, () => { this.validate(true); });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.word.length === 0) {
            validationErrors.push("A szó megadása kötelező.");
        }

        if (this.state.countThresholdRaw.length === 0) {
            validationErrors.push("A streamenkénti küszöbérték megadása kötelező.");
        }

        if (this.state.countThreshold < 1) {
            validationErrors.push("A streamenkénti küszöbérték értékének pozitívnak kell lennie.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default ChatbotBannedWords;
