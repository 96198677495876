import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ChatbotTextCommand } from "../models";
import { ChatbotTextCommandPayload } from "../payloads";

interface ChatbotTextCommandsProps {
    loading: number;
    items: ChatbotTextCommand[];
    create: (payload: ChatbotTextCommandPayload) => void;
    update: (id: number, payload: ChatbotTextCommandPayload) => void;
    delete: (id: number) => void;
}

interface ChatbotTextCommandsState {
    name: string;
    response: string;
    argCount: number;
    safe: boolean;
    isEnabled: boolean;
    argCountRaw: string;
    editIndex: number;
    deleteIndex: number;
    enableDisableIndex: number;
    validationErrors: string[];
}

export class ChatbotTextCommands extends React.PureComponent<ChatbotTextCommandsProps, ChatbotTextCommandsState> {
    constructor(props: ChatbotTextCommandsProps) {
        super(props);

        this.state = {
            name: "",
            response: "",
            argCount: 0,
            safe: true,
            isEnabled: true,
            argCountRaw: "0",
            editIndex: NaN,
            deleteIndex: NaN,
            enableDisableIndex: NaN,
            validationErrors: []
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                    <Button onClick={this.handleCreateClick} color="primary">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új szöveges parancs
                    </Button>
                </div>
                {this.props.items.length == null && <h2 className="center">Nem található szöveges parancs.</h2>}
                <ul className="list-group">
                    {this.props.items.map((textCommand, index) => {
                        const targetState = !textCommand.isEnabled;
                        return (
                            <li className={`list-group-item d-flex justify-content-between align-items-center ${textCommand.isEnabled ? "" : "text-muted chatbot-item-disabled"}`} key={`text-command-list-item-${index}`}>
                                <div><FontAwesomeIcon icon={["fas", "terminal"]} className="fa-fw mr-1" /> {textCommand.name}</div>
                                <div>
                                    <Button className="mr-2" color="primary" onClick={() => this.handleEditClick(index)} data-toggle="tooltip" data-placement="bottom" title="Szerkesztés">
                                        <FontAwesomeIcon id={`chatbot-text-command-edit-icon-${index}`} icon={["fas", "pencil-alt"]} className="fa-fw" />
                                    </Button>
                                    <Button className="mr-2" color="danger" onClick={() => this.handleDeleteClick(index)} data-toggle="tooltip" data-placement="bottom" title="Törlés">
                                        <FontAwesomeIcon id={`chatbot-text-command-delete-icon-${index}`} icon={["fas", "trash-alt"]} className="fa-fw" />
                                    </Button>
                                    <Button color={textCommand.isEnabled ? "warning" : "success"} onClick={() => this.handleEnableDisableClick(index)} data-toggle="tooltip" data-placement="bottom" title={targetState ? "Engedélyezés" : "Letiltás"}>
                                        <FontAwesomeIcon id={`chatbot-text-command-enable-disable-icon-${index}`} icon={["fas", textCommand.isEnabled ? "ban" : "check"]} className="fa-fw" />
                                    </Button>
                                </div>
                            </li>
                        );
                    })}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                {this.getEnableDisableModal()}
            </React.Fragment>
        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.items.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeEditModal}>Szöveges parancs {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputChatbotTextCommandKey">Parancs</label>
                        <input type="text" className="form-control" id="inputChatbotTextCommandKey" autoComplete="off" placeholder="Parancs" value={this.state.name} onChange={this.handleNameChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputChatbotTextCommandText">Válasz</label>
                        <textarea maxLength={250} rows={4} className="form-control" id="inputChatbotTextCommandText" autoComplete="off" placeholder="Válasz" value={this.state.response} onChange={this.handleResponseChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputChatbotTextCommandMinCooldownInMinutes">Argumentumok száma</label>
                        <input type="text" className="form-control" id="inputChatbotTextCommandMinCooldownInMinutes" autoComplete="off" placeholder="Argumentumok száma" value={this.state.argCountRaw} onChange={this.handleArgCountChange} />
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputChatbotTextCommandSafe" checked={this.state.safe} onChange={this.handleSafeChange} />
                        <label className="form-check-label" htmlFor="inputChatbotTextCommandSafe">Biztonságos</label>
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputChatbotTextCommandEnabled" checked={this.state.isEnabled} onChange={this.handleIsEnabledChange} />
                        <label className="form-check-label" htmlFor="inputChatbotTextCommandEnabled">Engedélyezve</label>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`client-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading && this.props.items.length > this.state.deleteIndex} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeDeleteModal}>Szöveges parancs</ModalHeader>
                <ModalBody>
                    {!isNaN(this.state.deleteIndex) &&
                        <div>
                            Biztosan törölni szeretnéd a(z) <strong>{this.props.items[this.state.deleteIndex].name}</strong> szöveges parancsot?
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleDelete} type="button" className="btn btn-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getEnableDisableModal = () => {
        const targetState = isNaN(this.state.enableDisableIndex) ? null : !this.props.items[this.state.enableDisableIndex].isEnabled;
        return (
            <Modal isOpen={!isNaN(this.state.enableDisableIndex) && !this.props.loading && this.props.items.length > this.state.enableDisableIndex} toggle={this.closeEnableDisableModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeEnableDisableModal}>Szöveges parancs {targetState == null ? "" : (targetState ? "engedélyezése" : "letiltása")}</ModalHeader>
                <ModalBody>
                    {!isNaN(this.state.enableDisableIndex) &&
                        <div>
                            Biztosan {targetState == null ? "" : (targetState ? "engedélyezni szeretnéd" : "le szeretnéd tiltani")} a(z) <strong>{this.props.items[this.state.enableDisableIndex].name}</strong> szöveges parancsot?
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleEnableDisable} type="button" className={`btn btn-${targetState ? "success" : "danger"}`}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> {targetState == null ? "" : (targetState ? "Engedélyezés" : "Letiltás")}
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEnableDisableClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleDelete = () => {
        this.props.delete(this.props.items[this.state.deleteIndex].id);

        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelEnableDisableClick = () => {
        this.setState({
            enableDisableIndex: NaN
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleNameChange = (e: any) => {
        this.setState({
            name: e.target.value
        }, () => { this.validate(true); });
    }

    private handleResponseChange = (e: any) => {
        this.setState({
            response: e.target.value
        }, () => { this.validate(true); });
    }

    private handleArgCountChange = (e: any) => {
        const raw = e.target.value;
        if (!isNaN(e.target.value)) {
            this.setState({
                argCount: parseInt(e.target.value),
                argCountRaw: raw
            }, () => { this.validate(true); });
        }
    }

    private handleSafeChange = (e: any) => {
        this.setState({
            safe: e.target.checked
        });
    }

    private handleIsEnabledChange = (e: any) => {
        this.setState({
            isEnabled: e.target.checked
        });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private closeEnableDisableModal = () => {
        this.setState({
            enableDisableIndex: NaN
        });
    }

    private handleDeleteClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private handleEnableDisableClick = (index: number) => {
        this.setState({
            enableDisableIndex: index
        });
    }

    private handleCreateClick = () => {
        this.resetForm();

        this.setState({
            editIndex: -1
        });
    }

    private handleEditClick = (index: number) => {
        this.resetForm();

        const item = this.props.items[index];

        this.setState({
            name: item.name,
            response: item.response,
            argCount: item.argCount,
            argCountRaw: item.argCount.toString(),
            safe: item.safe,
            isEnabled: item.isEnabled,
            editIndex: index
        });
    }

    private handleSaveClick = () => {
        if (this.state.editIndex === -1) {
            this.props.create({
                name: this.state.name,
                response: this.state.response,
                argCount: this.state.argCount,
                safe: this.state.safe,
                isEnabled: this.state.isEnabled
            });
        } else {
            this.props.update(this.props.items[this.state.editIndex].id, {
                name: this.state.name,
                response: this.state.response,
                argCount: this.state.argCount,
                safe: this.state.safe,
                isEnabled: this.state.isEnabled
            });
        }

        this.closeEditModal();
        this.resetForm();
    }

    private handleEnableDisable = () => {
        const item = this.props.items[this.state.enableDisableIndex];
        const enabled = !item.isEnabled;
        const payload: ChatbotTextCommandPayload = {
            ...item,
            isEnabled: enabled
        };

        this.props.update(item.id, payload);

        this.setState({
            enableDisableIndex: NaN
        });
    }

    private resetForm = () => {
        this.setState({
            name: "",
            response: "",
            argCount: 0,
            safe: true,
            isEnabled: true,
            argCountRaw: "0",
        }, () => { this.validate(true); });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.name.length === 0) {
            validationErrors.push("A parancs megadása kötelező.");
        }

        if (this.state.response.length === 0) {
            validationErrors.push("A válasz megadása kötelező.");
        }

        if (this.state.argCountRaw.length === 0) {
            validationErrors.push("Az argumentumok számának megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default ChatbotTextCommands;
