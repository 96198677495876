import * as React from "react";
import { Route, Redirect, Switch } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Clips from "./components/Clips";
import Chatters from "./components/Chatters";
import ChatbotSettings from "./components/ChatbotSettings";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import "chartjs-adapter-moment";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

import "./loader.css";
import "./custom.css";
import "react-datetime/css/react-datetime.css";

export default () => {
  return (
    <Layout>
      <Switch>
        <AuthorizeRoute exact path="/" component={Home} />
        <AuthorizeRoute exact path="/chatters" component={Chatters} />
        <AuthorizeRoute exact path="/clips" component={Clips} />
        <AuthorizeRoute exact path="/chatbot" component={ChatbotSettings} />
        <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
        <Redirect to="/" />
      </Switch>
    </Layout>
  );
};
