import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ChatbotWhoisDescription } from "../models";
import {
  ChatbotAliasNamePayload,
  ChatbotWhoisDescriptionPayload,
} from "../payloads";

interface ChatbotWhoisDescriptionsProps {
  loading: number;
  items: ChatbotWhoisDescription[];
  create: (payload: ChatbotWhoisDescriptionPayload) => void;
  update: (id: number, payload: ChatbotWhoisDescriptionPayload) => void;
  delete: (id: number) => void;
}

interface ChatbotWhoisDescriptionsState {
  name: string;
  description: string;
  aliasNames: ChatbotAliasNamePayload[];
  editIndex: number;
  deleteIndex: number;
  validationErrors: string[];
}

export class ChatbotWhoisDescriptions extends React.PureComponent<
  ChatbotWhoisDescriptionsProps,
  ChatbotWhoisDescriptionsState
> {
  constructor(props: ChatbotWhoisDescriptionsProps) {
    super(props);

    this.state = {
      name: "",
      description: "",
      aliasNames: [],
      editIndex: NaN,
      deleteIndex: NaN,
      validationErrors: [],
    };
  }

  public render() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-end mb-3">
          <Button onClick={this.handleCreateClick} color="primary">
            <FontAwesomeIcon icon={["fas", "plus"]} /> Új kicsoda szöveg
          </Button>
        </div>
        {this.props.items.length == null && (
          <h2 className="center">Nem található kicsoda szöveg.</h2>
        )}
        <ul className="list-group">
          {this.props.items.map((item, index) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={`whois-description-list-item-${index}`}
              >
                <div>
                  <FontAwesomeIcon
                    icon={["fas", "user"]}
                    className="fa-fw mr-1"
                  />{" "}
                  {item.name}
                </div>
                <div>
                  <Button
                    className="mr-2"
                    color="primary"
                    onClick={() => this.handleEditClick(index)}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Szerkesztés"
                  >
                    <FontAwesomeIcon
                      id={`chatbot-whois-description-edit-icon-${index}`}
                      icon={["fas", "pencil-alt"]}
                      className="fa-fw"
                    />
                  </Button>
                  <Button
                    className="mr-2"
                    color="danger"
                    onClick={() => this.handleDeleteClick(index)}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Törlés"
                  >
                    <FontAwesomeIcon
                      id={`chatbot-whois-description-delete-icon-${index}`}
                      icon={["fas", "trash-alt"]}
                      className="fa-fw"
                    />
                  </Button>
                </div>
              </li>
            );
          })}
        </ul>

        {this.getEditModal()}
        {this.getDeleteModal()}
      </React.Fragment>
    );
  }

  private getEditModal = () => {
    return (
      <Modal
        isOpen={
          !isNaN(this.state.editIndex) &&
          !this.props.loading &&
          this.props.items.length > this.state.editIndex
        }
        toggle={this.closeEditModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.closeEditModal}>
          Kicsoda szöveg{" "}
          {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="inputChatbotWhoisDescriptionName">Név</label>
            <input
              type="text"
              className="form-control"
              id="inputChatbotWhoisDescriptionName"
              autoComplete="off"
              placeholder="Név"
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="inputChatbotWhoisDescriptionDescription">
              Szöveg
            </label>
            <textarea
              className="form-control"
              id="inputChatbotWhoisDescriptionDescription"
              autoComplete="off"
              placeholder="Szöveg"
              rows={10}
              value={this.state.description}
              onChange={this.handleDescriptionChange}
            />
          </div>

          {this.state.aliasNames.map((aliasName, index) => {
            return (
              <div key={index} className="form-group">
                <label
                  htmlFor={`inputChatbotWhoisDescriptionAliasName${index}`}
                >
                  Alias név {index + 1}
                </label>
                <div className="d-flex justify-content-between">
                  <input
                    type="text"
                    className="form-control"
                    id={`inputChatbotWhoisDescriptionAliasName${index}`}
                    autoComplete="off"
                    placeholder="Alias név"
                    value={aliasName.alias}
                    onChange={(e) =>
                      this.handleAliasNameChange(index, e.target.value)
                    }
                  />
                  <Button
                    className="ml-2"
                    color="danger"
                    onClick={() => this.handleAliasNameDeleteClick(index)}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Törlés"
                  >
                    <FontAwesomeIcon
                      id={`chatbot-whois-description-alias-name-delete-icon-${index}`}
                      icon={["fas", "trash-alt"]}
                      className="fa-fw"
                    />
                  </Button>
                </div>
              </div>
            );
          })}
          <Button
            className="mr-2"
            color="primary"
            onClick={this.handleNewAliasNameClick}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Új alias név"
          >
            <FontAwesomeIcon icon={["fas", "plus"]} /> Új alias név
          </Button>

          {this.state.validationErrors.length > 0 && (
            <Card className="mt-2 mb-2 p-2 bg-danger text-white">
              {this.state.validationErrors.map((error, index) => {
                return (
                  <div
                    key={`client-form-validation-error-${index}`}
                    className="form-invalid"
                  >
                    {error}
                  </div>
                );
              })}
            </Card>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              onClick={this.handleSaveClick}
              type="button"
              className="btn btn-primary"
              disabled={!this.isValid()}
            >
              <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
            </button>

            <button
              className="btn btn-secondary"
              onClick={this.handleCancelEditClick}
            >
              <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private getDeleteModal = () => {
    return (
      <Modal
        isOpen={
          !isNaN(this.state.deleteIndex) &&
          !this.props.loading &&
          this.props.items.length > this.state.deleteIndex
        }
        toggle={this.closeDeleteModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.closeDeleteModal}>
          Kicsoda szöveg törlése
        </ModalHeader>
        <ModalBody>
          {!isNaN(this.state.deleteIndex) && (
            <div>
              Biztosan törölni szeretnéd a(z){" "}
              <strong>{this.props.items[this.state.deleteIndex].name}</strong>{" "}
              kicsoda szöveget?
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              onClick={this.handleDelete}
              type="button"
              className="btn btn-danger"
            >
              <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
            </button>

            <button
              className="btn btn-secondary"
              onClick={this.handleCancelDeleteClick}
            >
              <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private handleDelete = () => {
    this.props.delete(this.props.items[this.state.deleteIndex].id);

    this.setState({
      deleteIndex: NaN,
    });
  };

  private handleCancelDeleteClick = () => {
    this.setState({
      deleteIndex: NaN,
    });
  };

  private handleCancelEditClick = () => {
    this.setState({
      editIndex: NaN,
    });
  };

  private handleNameChange = (e: any) => {
    this.setState(
      {
        ...this.state,
        name: e.target.value,
      },
      () => {
        this.validate(true);
      }
    );
  };

  private handleDescriptionChange = (e: any) => {
    this.setState(
      {
        ...this.state,
        description: e.target.value,
      },
      () => {
        this.validate(true);
      }
    );
  };

  private handleAliasNameChange = (index: number, value: string) => {
    this.setState(
      {
        ...this.state,
        aliasNames: this.state.aliasNames.map((aliasName, innerIndex) => {
          return index === innerIndex
            ? {
                ...aliasName,
                alias: value,
              }
            : { ...aliasName };
        }),
      },
      () => {
        this.validate(true);
      }
    );
  };

  private handleAliasNameDeleteClick = (index: number) => {
    this.setState(
      {
        ...this.state,
        aliasNames: this.state.aliasNames.filter(
          (aliasName, innerIndex) => innerIndex !== index
        ),
      },
      () => {
        this.validate(true);
      }
    );
  };

  private handleNewAliasNameClick = () => {
    this.setState(
      {
        ...this.state,
        aliasNames: this.state.aliasNames.concat([{ id: 0, alias: "" }]),
      },
      () => {
        this.validate(true);
      }
    );
  };

  private closeEditModal = () => {
    this.setState({
      editIndex: NaN,
    });
  };

  private closeDeleteModal = () => {
    this.setState({
      deleteIndex: NaN,
    });
  };

  private handleDeleteClick = (index: number) => {
    this.setState({
      deleteIndex: index,
    });
  };

  private handleCreateClick = () => {
    this.resetForm();

    this.setState({
      editIndex: -1,
    });
  };

  private handleEditClick = (index: number) => {
    this.resetForm();

    const item = this.props.items[index];

    this.setState({
      name: item.name,
      description: item.description,
      aliasNames: item.aliasNames.map((x) => {
        return { ...x };
      }),
      editIndex: index,
    });
  };

  private handleSaveClick = () => {
    if (this.state.editIndex === -1) {
      this.props.create({
        name: this.state.name,
        description: this.state.description,
        aliasNames: this.state.aliasNames,
      });
    } else {
      this.props.update(this.props.items[this.state.editIndex].id, {
        name: this.state.name,
        description: this.state.description,
        aliasNames: this.state.aliasNames,
      });
    }

    this.closeEditModal();
    this.resetForm();
  };

  private resetForm = () => {
    this.setState(
      {
        name: "",
        description: "",
        aliasNames: [],
      },
      () => {
        this.validate(true);
      }
    );
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.name.length === 0) {
      validationErrors.push("A név megadása kötelező.");
    }

    if (this.state.description.length === 0) {
      validationErrors.push("A szöveg megadása kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };
}

export default ChatbotWhoisDescriptions;
