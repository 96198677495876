import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ChatbotAdmin } from "../models";
import { ChatbotAdminPayload } from "../payloads";

interface ChatbotAdminsProps {
    loading: number;
    items: ChatbotAdmin[];
    create: (payload: ChatbotAdminPayload) => void;
    update: (id: number, payload: ChatbotAdminPayload) => void;
    delete: (id: number) => void;
}

interface ChatbotAdminsState {
    name: string;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
}

export class ChatbotAdmins extends React.PureComponent<ChatbotAdminsProps, ChatbotAdminsState> {
    constructor(props: ChatbotAdminsProps) {
        super(props);

        this.state = {
            name: "",
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: []
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="mb-3">
                    <p>
                        <span>Itt csak a parancsokkal való konfigurálához lehet jogosultságot adni.</span>
                    </p>
                </div>
                <div className="d-flex justify-content-end mb-3">
                    <Button onClick={this.handleCreateClick} color="primary">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új admin
                    </Button>
                </div>
                {this.props.items.length == null && <h2 className="center">Nem található admin.</h2>}
                <ul className="list-group">
                    {this.props.items.map((item, index) => {
                        return (
                            <li className="list-group-item d-flex justify-content-between align-items-center" key={`text-command-list-item-${index}`}>
                                <div><FontAwesomeIcon icon={["fas", "user-cog"]} className="fa-fw mr-1" /> {item.name}</div>
                                <div>
                                    <Button className="mr-2" color="primary" onClick={() => this.handleEditClick(index)} data-toggle="tooltip" data-placement="bottom" title="Szerkesztés">
                                        <FontAwesomeIcon id={`chatbot-text-command-edit-icon-${index}`} icon={["fas", "pencil-alt"]} className="fa-fw" />
                                    </Button>
                                    <Button className="mr-2" color="danger" onClick={() => this.handleDeleteClick(index)} data-toggle="tooltip" data-placement="bottom" title="Törlés">
                                        <FontAwesomeIcon id={`chatbot-text-command-delete-icon-${index}`} icon={["fas", "trash-alt"]} className="fa-fw" />
                                    </Button>
                                </div>
                            </li>
                        );
                    })}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
            </React.Fragment>
        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.items.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeEditModal}>Admin {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputChatbotAdminName">Név</label>
                        <input type="text" className="form-control" id="inputChatbotAdminName" autoComplete="off" placeholder="Név" value={this.state.name} onChange={this.handleNameChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`client-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading && this.props.items.length > this.state.deleteIndex} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeDeleteModal}>Admin törlése</ModalHeader>
                <ModalBody>
                    {!isNaN(this.state.deleteIndex) &&
                        <div>
                            Biztosan törölni szeretnéd <strong>{this.props.items[this.state.deleteIndex].name}</strong> admint?
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleDelete} type="button" className="btn btn-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleDelete = () => {
        this.props.delete(this.props.items[this.state.deleteIndex].id);

        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleNameChange = (e: any) => {
        this.setState({
            name: e.target.value
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleDeleteClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private handleCreateClick = () => {
        this.resetForm();

        this.setState({
            editIndex: -1
        });
    }

    private handleEditClick = (index: number) => {
        this.resetForm();

        const item = this.props.items[index];

        this.setState({
            name: item.name,
            editIndex: index
        });
    }

    private handleSaveClick = () => {
        if (this.state.editIndex === -1) {
            this.props.create({ name: this.state.name });
        } else {
            this.props.update(this.props.items[this.state.editIndex].id, { name: this.state.name });
        }

        this.closeEditModal();
        this.resetForm();
    }

    private resetForm = () => {
        this.setState({
            name: ""
        }, () => { this.validate(true); });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.name.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default ChatbotAdmins;
