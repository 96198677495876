import * as React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import * as CiccaToolzStore from '../store/CiccaToolzStore';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import authService from '../components/api-authorization/AuthorizeService';
import { ApplicationState } from "../store";

type LayoutPropsType = CiccaToolzStore.CiccaToolzState
    & typeof CiccaToolzStore.actionCreators
    & RouteComponentProps<{}>
    & { children?: React.ReactNode };

interface LayoutState {
    userReceived: boolean;
    notLoggedIn: boolean;
}


class Layout extends React.PureComponent<LayoutPropsType, LayoutState> {
    constructor(props: LayoutPropsType) {
        super(props);

        this.state = {
            userReceived: false,
            notLoggedIn: false
        };
    }

    componentDidMount() {
        authService.isAuthenticated().then(authenticated => {
            this.setState({
                userReceived: true
            });

            if (authenticated) {
                this.props.getLoggedInUser();
            } else {
                this.setState({
                    notLoggedIn: true
                });
            }
        });
    }

    render() {
        const user = this.props.loggedInUser;

        if (user != null) {
            if (!user.isAdmin && (this.props.location.pathname.toLowerCase().startsWith("/chatters") || this.props.location.pathname.toLowerCase().startsWith("/clips"))) {
                return <Redirect to="/" />
            }
        }

        return (
            <React.Fragment>
                <NavMenu />
                <Container>
                    {this.state.userReceived && this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}

export default compose(
    withRouter,
    connect(
        (state: ApplicationState) => state.ciccaToolz,
        CiccaToolzStore.actionCreators
    )
)(Layout) as React.ComponentType;
