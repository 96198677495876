import * as React from "react";
import { Card, CardBody } from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import * as CiccaToolzStore from "../store/CiccaToolzStore";


type HomePropsType = CiccaToolzStore.CiccaToolzState
    & typeof CiccaToolzStore.actionCreators
    & RouteComponentProps<{}>;

class Home extends React.PureComponent<HomePropsType> {
    public render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody className="d-flex flex-column align-items-center">
                        {this.props.loggedInUser != null && this.props.loggedInUser.isAdmin &&
                            <React.Fragment>
                                <div className="mb-3">
                                    <Link to="/chatters" className="btn btn-lg btn-primary">Nézők</Link>
                                </div>
                                <div className="mb-3">
                                    <Link to="/clips" className="btn btn-lg btn-primary">Klipek</Link>
                                </div>
                            </React.Fragment>
                        }
                        <div>
                            <Link to="/chatbot" className="btn btn-lg btn-primary">Kockakutyus</Link>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.ciccaToolz,
    CiccaToolzStore.actionCreators
)(Home as any);
