import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ChatbotRandomText } from "../models";
import { ChatbotRandomTextPayload } from "../payloads";

interface ChatbotRandomTextsProps {
    loading: number;
    items: ChatbotRandomText[];
    create: (payload: ChatbotRandomTextPayload) => void;
    update: (id: number, payload: ChatbotRandomTextPayload) => void;
    delete: (id: number) => void;
}

interface ChatbotRandomTextsState {
    key: string;
    text: string;
    minCooldown: number;
    maxCooldown: number;
    isEnabled: boolean;
    minCooldownRaw: string;
    maxCooldownRaw: string;
    editIndex: number;
    deleteIndex: number;
    enableDisableIndex: number;
    validationErrors: string[];
}

export class ChatbotRandomTexts extends React.PureComponent<ChatbotRandomTextsProps, ChatbotRandomTextsState> {
    constructor(props: ChatbotRandomTextsProps) {
        super(props);

        this.state = {
            key: "",
            text: "",
            minCooldown: 0,
            maxCooldown: 0,
            isEnabled: true,
            minCooldownRaw: "0",
            maxCooldownRaw: "0",
            editIndex: NaN,
            deleteIndex: NaN,
            enableDisableIndex: NaN,
            validationErrors: []
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="mb-3">
                    <p>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} color="red" className="fa-fw mr-2" />
                        <span><i>Bármelyik</i> random szövegen elvégzett <i>bármilyen</i> változtatás az <strong>*összes*</strong> random szöveg időzítésének újraindítását vonja maga után.</span>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} color="red" className="fa-fw ml-2" />
                    </p>
                </div>
                <div className="d-flex justify-content-end mb-3">
                    <Button onClick={this.handleCreateClick} color="primary">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új random szöveg
                    </Button>
                </div>
                {this.props.items.length == null && <h2 className="center">Nem található random szöveg.</h2>}
                <ul className="list-group">
                    {this.props.items.map((randomText, index) => {
                        const targetState = !randomText.isEnabled;
                        return (
                            <li className={`list-group-item d-flex justify-content-between align-items-center ${randomText.isEnabled ? "" : "text-muted chatbot-item-disabled"}`} key={`random-text-list-item-${index}`}>
                                <div><FontAwesomeIcon icon={["fas", "scroll"]} className="fa-fw mr-1" /> {randomText.key}</div>
                                <div>
                                    <Button className="mr-2" color="primary" onClick={() => this.handleEditClick(index)} data-toggle="tooltip" data-placement="bottom" title="Szerkesztés">
                                        <FontAwesomeIcon id={`chatbot-random-text-edit-icon-${index}`} icon={["fas", "pencil-alt"]} className="fa-fw" />
                                    </Button>
                                    <Button className="mr-2" color="danger" onClick={() => this.handleDeleteClick(index)} data-toggle="tooltip" data-placement="bottom" title="Törlés">
                                        <FontAwesomeIcon id={`chatbot-random-text-delete-icon-${index}`} icon={["fas", "trash-alt"]} className="fa-fw" />
                                    </Button>
                                    <Button color={randomText.isEnabled ? "warning" : "success"} onClick={() => this.handleEnableDisableClick(index)} data-toggle="tooltip" data-placement="bottom" title={targetState ? "Engedélyezés" : "Letiltás"}>
                                        <FontAwesomeIcon id={`chatbot-random-text-enable-disable-icon-${index}`} icon={["fas", randomText.isEnabled ? "ban" : "check"]} className="fa-fw" />
                                    </Button>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                {this.getEditModal()}
                {this.getDeleteModal()}
                {this.getEnableDisableModal()}
            </React.Fragment>
        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.items.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeEditModal}>Random szöveg {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputChatbotRandomTextKey">Azonosító</label>
                        <input type="text" className="form-control" id="inputChatbotRandomTextKey" autoComplete="off" placeholder="Azonosító" value={this.state.key} onChange={this.handleKeyChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputChatbotRandomTextText">Szöveg</label>
                        <textarea maxLength={250} rows={4} className="form-control" id="inputChatbotRandomTextText" autoComplete="off" placeholder="Szöveg" value={this.state.text} onChange={this.handleTextChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputChatbotRandomTextMinCooldownInMinutes">Cooldown minimális értéke (perc)</label>
                        <input type="text" className="form-control" id="inputChatbotRandomTextMinCooldownInMinutes" autoComplete="off" placeholder="Minimum cooldown" value={this.state.minCooldownRaw} onChange={this.handleMinCooldownChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputChatbotRandomTextMaxCooldownInMinutes">Cooldown maximális értéke (perc)</label>
                        <input type="text" className="form-control" id="inputChatbotRandomTextMaxCooldownInMinutes" autoComplete="off" placeholder="Maximum cooldown" value={this.state.maxCooldownRaw} onChange={this.handleMaxCooldownChange} />
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputChatbotRandomTextEnabled" checked={this.state.isEnabled} onChange={this.handleIsEnabledChange} />
                        <label className="form-check-label" htmlFor="inputChatbotRandomTextEnabled">Engedélyezve</label>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`client-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading && this.props.items.length > this.state.deleteIndex} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeDeleteModal}>Random szöveg törlése</ModalHeader>
                <ModalBody>
                    {!isNaN(this.state.deleteIndex) &&
                        <div>
                            Biztosan törölni szeretnéd a(z) <strong>{this.props.items[this.state.deleteIndex].key}</strong> azonosítójú random szöveget?
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleDelete} type="button" className="btn btn-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getEnableDisableModal = () => {
        const targetState = isNaN(this.state.enableDisableIndex) ? null : !this.props.items[this.state.enableDisableIndex].isEnabled;
        return (
            <Modal isOpen={!isNaN(this.state.enableDisableIndex) && !this.props.loading && this.props.items.length > this.state.enableDisableIndex} toggle={this.closeEnableDisableModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.closeEnableDisableModal}>Random szöveg {targetState == null ? "" : (targetState ? "engedélyezése" : "letiltása")}</ModalHeader>
                <ModalBody>
                    {!isNaN(this.state.enableDisableIndex) &&
                        <div>
                            Biztosan {targetState == null ? "" : (targetState ? "engedélyezni szeretnéd" : "le szeretnéd tiltani")} a(z) <strong>{this.props.items[this.state.enableDisableIndex].key}</strong> azonosítójú random szöveget?
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleEnableDisable} type="button" className={`btn btn-${targetState ? "success" : "danger"}`}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> {targetState == null ? "" : (targetState ? "Engedélyezés" : "Letiltás")}
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEnableDisableClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleDelete = () => {
        this.props.delete(this.props.items[this.state.deleteIndex].id);

        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCancelEnableDisableClick = () => {
        this.setState({
            enableDisableIndex: NaN
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleKeyChange = (e: any) => {
        this.setState({
            key: e.target.value
        }, () => { this.validate(true); });
    }

    private handleTextChange = (e: any) => {
        this.setState({
            text: e.target.value
        }, () => { this.validate(true); });
    }

    private handleMinCooldownChange = (e: any) => {
        const raw = e.target.value;
        const targetValue = parseInt(raw.replace(/\D/, ""));
        if (!isNaN(targetValue)) {
            this.setState({
                minCooldown: targetValue,
                minCooldownRaw: raw
            }, () => { this.validate(true); });
        }
    }

    private handleMaxCooldownChange = (e: any) => {
        const raw = e.target.value;
        const targetValue = parseInt(raw.replace(/\D/, ""));
        if (!isNaN(targetValue)) {
            this.setState({
                maxCooldown: targetValue,
                maxCooldownRaw: raw
            }, () => { this.validate(true); });
        }
    }

    private handleIsEnabledChange = (e: any) => {
        this.setState({
            isEnabled: e.target.checked
        });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private closeEnableDisableModal = () => {
        this.setState({
            enableDisableIndex: NaN
        });
    }

    private handleDeleteClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private handleEnableDisableClick = (index: number) => {
        this.setState({
            enableDisableIndex: index
        });
    }

    private handleCreateClick = () => {
        this.resetForm();

        this.setState({
            editIndex: -1
        });
    }

    private handleEditClick = (index: number) => {
        this.resetForm();

        const item = this.props.items[index];

        this.setState({
            key: item.key,
            text: item.text,
            minCooldown: item.minCooldownInMinutes,
            maxCooldown: item.minCooldownInMinutes,
            minCooldownRaw: item.minCooldownInMinutes.toString(),
            maxCooldownRaw: item.maxCooldownInMinutes.toString(),
            isEnabled: item.isEnabled,
            editIndex: index
        });
    }

    private handleSaveClick = () => {
        if (this.state.editIndex === -1) {
            this.props.create({
                key: this.state.key,
                text: this.state.text,
                minCooldownInMinutes: this.state.minCooldown,
                maxCooldownInMinutes: this.state.maxCooldown,
                isEnabled: this.state.isEnabled
            });
        } else {
            this.props.update(this.props.items[this.state.editIndex].id, {
                key: this.state.key,
                text: this.state.text,
                minCooldownInMinutes: this.state.minCooldown,
                maxCooldownInMinutes: this.state.maxCooldown,
                isEnabled: this.state.isEnabled
            });
        }

        this.closeEditModal();
        this.resetForm();
    }

    private handleEnableDisable = () => {
        const item = this.props.items[this.state.enableDisableIndex];
        const enabled = !item.isEnabled;
        const payload: ChatbotRandomTextPayload = {
            ...item,
            isEnabled: enabled
        };

        this.props.update(item.id, payload);

        this.setState({
            enableDisableIndex: NaN
        });
    }

    private resetForm = () => {
        this.setState({
            key: "",
            text: "",
            minCooldown: 0,
            maxCooldown: 0,
            isEnabled: true,
            minCooldownRaw: "0",
            maxCooldownRaw: "0",
        }, () => { this.validate(true); });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.key.length === 0) {
            validationErrors.push("Az azonosító megadása kötelező.");
        }

        if (this.state.text.length === 0) {
            validationErrors.push("A szöveg megadása kötelező.");
        }

        if (this.state.minCooldownRaw.length === 0) {
            validationErrors.push("A cooldown minimális értékének megadása kötelező.");
        }

        if (this.state.maxCooldownRaw.length === 0) {
            validationErrors.push("A cooldown maximális értékének megadása kötelező.");
        }

        if (this.state.minCooldown === 0) {
            validationErrors.push("A cooldown minimális értéke nem lehet nulla.");
        }

        if (this.state.maxCooldown === 0) {
            validationErrors.push("A cooldown maximális értéke nem lehet nulla.");
        }

        if (this.state.minCooldown > this.state.maxCooldown) {
            validationErrors.push("A cooldown minimális értéke nem lehet nagyobb, mint a maximális.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default ChatbotRandomTexts;
