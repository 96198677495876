import * as React from "react";
import { TwitchClip } from "../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody } from "reactstrap";
import moment from "moment";

interface ClipCardProps {
  clip: TwitchClip;
}

export class ClipCard extends React.PureComponent<ClipCardProps, {}> {
  public render() {
    const clip = this.props.clip;

    return (
      <Card className="clip-card m-2 p-1">
        <a href={clip.url} target="_blank" rel="noopener noreferrer">
          <img
            className="card-img-top"
            src={clip.thumbnailUrl}
            alt={clip.title}
          />
        </a>
        <CardBody>
          <a href={clip.url} target="_blank" rel="noopener noreferrer">
            <h5 className="card-title">{clip.title}</h5>
          </a>
          <div className="mb-1">
            <FontAwesomeIcon
              icon={["fas", "calendar-alt"]}
              className="fa-fw mr-1"
            />{" "}
            {moment(clip.createdAt).format("YYYY.MM.DD HH:mm:ss")}
          </div>
          <div className="mb-1">
            <FontAwesomeIcon icon={["fas", "gamepad"]} className="fa-fw mr-1" />{" "}
            {clip.category}
          </div>
          <div className="mb-1">
            <FontAwesomeIcon icon={["fas", "user"]} className="fa-fw mr-1" />{" "}
            {clip.curatorDisplayName}
          </div>
          <div className="mb-1">
            <FontAwesomeIcon icon={["fas", "clock"]} className="fa-fw mr-1" />{" "}
            {this.getDurationString(clip.duration)}
          </div>
          <div>
            <FontAwesomeIcon icon={["fas", "eye"]} className="fa-fw mr-1" />{" "}
            {clip.views}
          </div>
        </CardBody>
      </Card>
    );
  }

  private getDurationString = (duration: number) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);
    const seconds = Math.round(duration - hours * 3600 - minutes * 60);

    return `${hours > 0 ? this.padToTwo(hours) + ":" : ""}${this.padToTwo(
      minutes
    )}:${this.padToTwo(seconds)}`;
  };

  private padToTwo = (input: number) =>
    input <= 99 ? `0${input}`.slice(-2) : input;
}

export default ClipCard;
