import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as CiccaToolzStore from '../store/CiccaToolzStore'
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileOnly } from 'react-device-detect';

type NavMenuProps = CiccaToolzStore.CiccaToolzState
    & typeof CiccaToolzStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuState {
    isOpen: boolean;
}

class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/" className="">CiccaToolz</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex ml-auto justify-content-end" isOpen={this.state.isOpen} onClick={this.toggle} navbar>
                            <NavLink tag={Link} className="text-dark" to="/"><FontAwesomeIcon icon={["fas", "home"]} className="fa-fw mr-1" /> Kezdőlap</NavLink>
                            {this.props.loggedInUser != null && this.props.loggedInUser.isAdmin &&
                                <React.Fragment>
                                    <NavLink tag={Link} className="text-dark" to="/chatters"><FontAwesomeIcon icon={["fas", "user-friends"]} className="fa-fw mr-1" /> Nézők</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/clips"><FontAwesomeIcon icon={["fas", "film"]} className="fa-fw mr-1" /> Klipek</NavLink>
                                </React.Fragment>
                            }
                            <NavLink tag={Link} className="text-dark" to="/chatbot"><FontAwesomeIcon icon={["fas", "dog"]} className="fa-fw mr-1" /> Kockakutyus</NavLink>
                            <a className="text-dark nav-link" href="/Identity/Account/Manage/ChangePassword"><FontAwesomeIcon icon={["fas", "cog"]} className="fa-fw mr-1" /> Jelszóváltoztatás</a>
                            <NavLink tag={Link} className="text-dark" to={ApplicationPaths.LogOut}><FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="fa-fw mr-1" /> Kijelentkezés</NavLink>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        if (isMobileOnly) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }
}

export default connect(
    (state: ApplicationState) => state.ciccaToolz,
    CiccaToolzStore.actionCreators
)(NavMenu as any);
