import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store/index";
import * as CiccaToolzStore from "../store/CiccaToolzStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Countdown from "react-countdown";
import ChatbotAllowedNames from "./ChatbotAllowedNames";
import ChatbotBlockedNames from "./ChatbotBlockedNames";
import ChatbotRandomTexts from "./ChatbotRandomTexts";
import ChatbotTextCommands from "./ChatbotTextCommands";
import ChatbotAdmins from "./ChatbotAdmins";
import ChatbotBannedWords from "./ChatbotBannedWords";
import ChatbotPuzzles from "./ChatbotPuzzles";
import ChatbotPuzzleHints from "./ChatbotPuzzleHints";
import ChatbotWhoisDescriptions from "./ChatbotWhoisDescriptions";
import { ChatbotSettingsPayload } from "../payloads";

type ChatbotManagerPropsType = CiccaToolzStore.CiccaToolzState &
  typeof CiccaToolzStore.actionCreators &
  RouteComponentProps<{}>;

interface ChatbotManagerState {
  chatbotSettings: ChatbotSettingsPayload;
  chatbotMessage: string;
  sendMessageOnEnter: boolean;
  forceShutdownModalOpen: boolean;
  resetTreasureHuntModalOpen: boolean;
}

const countdownRenderer = (options: {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (options.completed) {
    return <span className="h5">Újratöltés...</span>;
  } else {
    return <span className="h5">{options.seconds}</span>;
  }
};

class ChatbotManager extends React.PureComponent<
  ChatbotManagerPropsType,
  ChatbotManagerState
> {
  private messageTextareaRef: HTMLTextAreaElement | null = null;

  constructor(props: ChatbotManagerPropsType) {
    super(props);

    this.state = {
      chatbotSettings: {
        ...props.chatbotSettings,
      },
      chatbotMessage: "",
      sendMessageOnEnter: true,
      forceShutdownModalOpen: false,
      resetTreasureHuntModalOpen: false,
    };
  }

  componentWillMount() {
    this.props.getChatbotSettings();
    this.props.getChatbotRandomTexts();
    this.props.getChatbotTextCommands();
    this.props.getChatbotAllowedNames();
    this.props.getChatbotBlockedNames();
    this.props.getChatbotAdmins();
    this.props.getChatbotBannedWords();
    //this.props.getChatbotPuzzles();
    //this.props.getChatbotPuzzleHints();
    this.props.getChatbotWhoisDescriptions();
  }

  componentWillReceiveProps(nextProps: ChatbotManagerPropsType) {
    if (this.props.loading && !nextProps.loading) {
      this.setState({
        chatbotSettings: {
          ...nextProps.chatbotSettings,
        },
      });
    }
  }

  public render() {
    return (
      <React.Fragment>
        <div className={`loader ${this.props.loading ? "show" : ""}`}>
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <h4 className="mb-3">Kockakutyus</h4>

        <div className="tabbable full-width-tabs mt-4">
          <ul
            className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center"
            id="chatbot-settings-tab-list"
            role="tablist"
          >
            <li className="d-flex">
              <a
                className="nav-link active"
                id="global-settings-tab"
                data-toggle="pill"
                href="#global-settings"
                role="tab"
                aria-controls="global"
                aria-selected="true"
              >
                Globális beállítások
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="random-texts-tab"
                data-toggle="pill"
                href="#random-texts"
                role="tab"
                aria-controls="random"
                aria-selected="false"
              >
                Random szövegek
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="text-commands-tab"
                data-toggle="pill"
                href="#text-commands"
                role="tab"
                aria-controls="text"
                aria-selected="false"
              >
                Szöveges parancsok
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="allowed-names-tab"
                data-toggle="pill"
                href="#allowed-names"
                role="tab"
                aria-controls="allowed"
                aria-selected="false"
              >
                Engedélyezett nevek
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="blocked-names-tab"
                data-toggle="pill"
                href="#blocked-names"
                role="tab"
                aria-controls="blocked"
                aria-selected="false"
              >
                Tiltott nevek
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="admins-tab"
                data-toggle="pill"
                href="#admins"
                role="tab"
                aria-controls="admin"
                aria-selected="false"
              >
                Adminok
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="banned-words-tab"
                data-toggle="pill"
                href="#banned-words"
                role="tab"
                aria-controls="banned"
                aria-selected="false"
              >
                Tiltott szavak
              </a>
            </li>
            {/*<li className="d-flex">*/}
            {/*    <a className="nav-link" id="puzzles-tab" data-toggle="pill" href="#puzzles" role="tab" aria-controls="puzzle" aria-selected="false">Rejtvények</a>*/}
            {/*</li>*/}
            {/*<li className="d-flex">*/}
            {/*    <a className="nav-link" id="puzzle-hints-tab" data-toggle="pill" href="#puzzle-hints" role="tab" aria-controls="hint" aria-selected="false">Nyomok</a>*/}
            {/*</li>*/}
            <li className="d-flex">
              <a
                className="nav-link"
                id="whois-descriptions-tab"
                data-toggle="pill"
                href="#whois-descriptions"
                role="tab"
                aria-controls="whois"
                aria-selected="false"
              >
                Kicsoda szövegek
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="message-tab"
                data-toggle="pill"
                href="#chatbot-message"
                role="tab"
                aria-controls="message"
                aria-selected="false"
              >
                Üzenet küldése
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content card" id="chatbot-settings-tab-content">
          <div
            className="tab-pane fade p-3 show active"
            id="global-settings"
            role="tabpanel"
            aria-labelledby="global-settings-tab"
          >
            <div>
              <div className="form-check mb-2 pl-0">
                <input
                  id="inputEnabled"
                  type="checkbox"
                  checked={this.state.chatbotSettings.enabled}
                  onChange={this.handleEnabledChange}
                  disabled={this.state.chatbotSettings.autoStartEnabled}
                />
                <label className="ml-2" htmlFor="inputEnabled">
                  Bekapcsolva
                </label>
              </div>
              <div className="form-check mb-2 pl-0">
                <input
                  id="inputAutoStartEnabled"
                  type="checkbox"
                  checked={this.state.chatbotSettings.autoStartEnabled}
                  onChange={this.handleAutoStartEnabledChange}
                />
                <label className="ml-2" htmlFor="inputAutoStartEnabled">
                  Automatikus indulás
                </label>
              </div>
              <div className="form-check mb-2 pl-0">
                <input
                  id="inputMockOnlineEnabled"
                  type="checkbox"
                  checked={this.state.chatbotSettings.mockOnline}
                  onChange={this.handleMockOnlineChange}
                />
                <label className="ml-2" htmlFor="inputMockOnlineEnabled">
                  Futó stream szimulálása
                </label>
              </div>
              <div className="form-check mb-2 pl-0">
                <input
                  id="inputTimeoutOrBanBroadcaster"
                  type="checkbox"
                  checked={this.state.chatbotSettings.timeoutOrBanBroadcaster}
                  onChange={this.handleTimeoutOrBanBroadcasterChange}
                />
                <label className="ml-2" htmlFor="inputTimeoutOrBanBroadcaster">
                  Közvetítő timeoutolása, bannolása
                </label>
              </div>
              <div className="form-check mb-2 pl-0">
                <input
                  id="inputTimeoutOrBanModerators"
                  type="checkbox"
                  checked={this.state.chatbotSettings.timeoutOrBanModerators}
                  onChange={this.handleTimeoutOrBanModeratorsChange}
                />
                <label className="ml-2" htmlFor="inputTimeoutOrBanModerators">
                  Moderátorok timeoutolása, bannolása
                </label>
              </div>
              <div className="form-check mb-2 pl-0">
                <input
                  id="inputTimeoutOrBanVips"
                  type="checkbox"
                  checked={this.state.chatbotSettings.timeoutOrBanVips}
                  onChange={this.handleTimeoutOrBanVipsChange}
                />
                <label className="ml-2" htmlFor="inputTimeoutOrBanVips">
                  VIP-k timeoutolása, bannolása
                </label>
              </div>
              {/*<div className="form-check mb-2 pl-0">*/}
              {/*    <input id="inputPuzzlesEnabled" type="checkbox" checked={this.state.chatbotSettings.puzzlesEnabled} onChange={this.handlePuzzlesEnabledChange} />*/}
              {/*    <label className="ml-2" htmlFor="inputPuzzlesEnabled">Rejtvények engedélyezve</label>*/}
              {/*</div>*/}
            </div>

            <div className="mt-3">
              <button
                onClick={this.handleUpdateSettings}
                type="button"
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                Mentés
              </button>
            </div>

            <div className="mt-3">
              <button
                onClick={this.handleForceShutdownClick}
                type="button"
                className="btn btn-danger"
              >
                <FontAwesomeIcon icon={["fas", "power-off"]} className="mr-1" />{" "}
                Kényszerített újraindítás
              </button>
            </div>

            {/*<div className="mt-3">*/}
            {/*    <button onClick={this.toggleResetTreasureHuntModal} type="button" className="btn btn-warning">*/}
            {/*        <FontAwesomeIcon icon={["fas", "sync"]} className="mr-1" /> Kincsvadászat alaphelyzetbe állítása*/}
            {/*    </button>*/}
            {/*</div>*/}
          </div>
          <div
            className="tab-pane fade p-3"
            id="random-texts"
            role="tabpanel"
            aria-labelledby="random-texts-tab"
          >
            <ChatbotRandomTexts
              loading={this.props.loading}
              items={this.props.chatbotRandomTexts}
              create={this.props.createChatbotRandomText}
              update={this.props.updateChatbotRandomText}
              delete={this.props.deleteChatbotRandomText}
            />
          </div>
          <div
            className="tab-pane fade p-3"
            id="text-commands"
            role="tabpanel"
            aria-labelledby="text-commands-tab"
          >
            <ChatbotTextCommands
              loading={this.props.loading}
              items={this.props.chatbotTextCommands}
              create={this.props.createChatbotTextCommand}
              update={this.props.updateChatbotTextCommand}
              delete={this.props.deleteChatbotTextCommand}
            />
          </div>
          <div
            className="tab-pane fade p-3"
            id="allowed-names"
            role="tabpanel"
            aria-labelledby="allowed-names-tab"
          >
            <ChatbotAllowedNames
              loading={this.props.loading}
              items={this.props.chatbotAllowedNames}
              create={this.props.createChatbotAllowedName}
              update={this.props.updateChatbotAllowedName}
              delete={this.props.deleteChatbotAllowedName}
            />
          </div>
          <div
            className="tab-pane fade p-3"
            id="blocked-names"
            role="tabpanel"
            aria-labelledby="blocked-names-tab"
          >
            <ChatbotBlockedNames
              loading={this.props.loading}
              items={this.props.chatbotBlockedNames}
              create={this.props.createChatbotBlockedName}
              update={this.props.updateChatbotBlockedName}
              delete={this.props.deleteChatbotBlockedName}
            />
          </div>
          <div
            className="tab-pane fade p-3"
            id="admins"
            role="tabpanel"
            aria-labelledby="admins-tab"
          >
            <ChatbotAdmins
              loading={this.props.loading}
              items={this.props.chatbotAdmins}
              create={this.props.createChatbotAdmin}
              update={this.props.updateChatbotAdmin}
              delete={this.props.deleteChatbotAdmin}
            />
          </div>
          <div
            className="tab-pane fade p-3"
            id="banned-words"
            role="tabpanel"
            aria-labelledby="banned-words-tab"
          >
            <ChatbotBannedWords
              loading={this.props.loading}
              items={this.props.chatbotBannedWords}
              create={this.props.createChatbotBannedWord}
              update={this.props.updateChatbotBannedWord}
              delete={this.props.deleteChatbotBannedWord}
            />
          </div>
          {/*<div className="tab-pane fade p-3" id="puzzles" role="tabpanel" aria-labelledby="puzzles-tab">*/}
          {/*    <ChatbotPuzzles*/}
          {/*        loading={this.props.loading}*/}
          {/*        items={this.props.chatbotPuzzles}*/}
          {/*        create={this.props.createChatbotPuzzle}*/}
          {/*        update={this.props.updateChatbotPuzzle}*/}
          {/*        delete={this.props.deleteChatbotPuzzle}*/}
          {/*    />*/}
          {/*</div>*/}
          {/*<div className="tab-pane fade p-3" id="puzzle-hints" role="tabpanel" aria-labelledby="puzzle-hints-tab">*/}
          {/*    <ChatbotPuzzleHints*/}
          {/*        loading={this.props.loading}*/}
          {/*        items={this.props.chatbotPuzzleHints}*/}
          {/*        puzzles={this.props.chatbotPuzzles}*/}
          {/*        create={this.props.createChatbotPuzzleHint}*/}
          {/*        update={this.props.updateChatbotPuzzleHint}*/}
          {/*        delete={this.props.deleteChatbotPuzzleHint}*/}
          {/*    />*/}
          {/*</div>*/}
          <div
            className="tab-pane fade p-3"
            id="whois-descriptions"
            role="tabpanel"
            aria-labelledby="whois-descriptions-tab"
          >
            <ChatbotWhoisDescriptions
              loading={this.props.loading}
              items={this.props.chatbotWhoisDescriptions}
              create={this.props.createChatbotWhoisDescription}
              update={this.props.updateChatbotWhoisDescription}
              delete={this.props.deleteChatbotWhoisDescription}
            />
          </div>
          <div
            className="tab-pane fade p-3"
            id="chatbot-message"
            role="tabpanel"
            aria-labelledby="message-tab"
          >
            <div className="form-group">
              <label htmlFor="inputChatbotMessage">Üzenet</label>
              <textarea
                ref={(node) => (this.messageTextareaRef = node)}
                maxLength={250}
                rows={4}
                className="form-control"
                id="inputChatbotMessage"
                autoComplete="off"
                placeholder="Üzenet"
                value={this.state.chatbotMessage}
                onChange={this.handleChatbotMessageChange}
                onKeyDown={this.handleChatbotMessageKeyDown}
              />
            </div>

            <div className="form-check mb-2 pl-0">
              <input
                id="inputSendMessageOnEnter"
                type="checkbox"
                checked={this.state.sendMessageOnEnter}
                onChange={this.handleSendMessageOnEnterChange}
              />
              <label className="ml-2" htmlFor="inputSendMessageOnEnter">
                Küldés Enterrel
              </label>
            </div>

            <p>
              <i>
                Az üzenet csak akkor kerül elküldésre, ha a küldés pillanatában
                a chatbot éppen aktív.
              </i>
            </p>

            <div className="mt-3">
              <button
                onClick={this.handleSendChatbotMessage}
                type="button"
                className="btn btn-primary"
                disabled={this.state.chatbotMessage.length === 0}
              >
                <FontAwesomeIcon
                  icon={["fas", "paper-plane"]}
                  className="mr-1"
                />{" "}
                Küldés
              </button>
            </div>
          </div>
        </div>

        {this.getForceShutdownConfirmModal()}
        {this.getResetTreasureHuntConfirmModal()}
      </React.Fragment>
    );
  }

  private getForceShutdownConfirmModal = () => {
    return (
      <Modal
        isOpen={this.state.forceShutdownModalOpen}
        toggle={this.handleCancelForceShutdownClick}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.handleCancelForceShutdownClick}>
          Kényszerített újraindítás
        </ModalHeader>
        <ModalBody>
          {this.props.shutdownInitiated && (
            <div className="d-flex justify-content-center">
              <Countdown
                date={Date.now() + 30000}
                renderer={countdownRenderer}
                onComplete={this.onShutdownCountdownComplete}
              />
            </div>
          )}
          {!this.props.shutdownInitiated && (
            <div>
              <div>
                <p>
                  Biztosan ki szeretnéd kényszeríteni a service újraindítását?
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={["fas", "exclamation-triangle"]}
                    color="red"
                    className="fa-fw mr-2"
                  />
                  <span>
                    <strong>Figyelem!</strong> A gomb megnyomását követően a web
                    service leáll, majd 10 másodperc múlva újraindul. A chatbot
                    és jelen weboldal nem lesz elérhető kb. 15-20 másodpercig.
                    Az oldal automatikusan újratöltődik 30 másodperc után.
                  </span>
                  <FontAwesomeIcon
                    icon={["fas", "exclamation-triangle"]}
                    color="red"
                    className="fa-fw ml-2"
                  />
                </p>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-4">
                <button
                  onClick={this.handleConfirmForceShutdownClick}
                  type="button"
                  className="btn btn-danger"
                >
                  <FontAwesomeIcon
                    icon={["fas", "power-off"]}
                    className="mr-1"
                  />{" "}
                  Kényszerített újraindítás
                </button>

                <button
                  className="btn btn-secondary"
                  onClick={this.handleCancelForceShutdownClick}
                >
                  <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                </button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  };

  private getResetTreasureHuntConfirmModal = () => {
    return (
      <Modal
        isOpen={this.state.resetTreasureHuntModalOpen}
        toggle={this.toggleResetTreasureHuntModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.toggleResetTreasureHuntModal}>
          Kincsvadászat alaphelyzetbe állítása
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              <p>
                Biztosan alaphelyzetbe szeretnéd állítani a kincsvadászatot?
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-4">
              <button
                onClick={this.handleConfirmResetTreasureHuntClick}
                type="button"
                className="btn btn-warning"
              >
                <FontAwesomeIcon icon={["fas", "sync"]} className="mr-1" />{" "}
                Alaphelyzetbe állítás
              </button>

              <button
                className="btn btn-secondary"
                onClick={this.toggleResetTreasureHuntModal}
              >
                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private onShutdownCountdownComplete = () => {
    window.location.reload();
  };

  private handleForceShutdownClick = () => {
    this.setState({
      forceShutdownModalOpen: true,
    });
  };

  private handleCancelForceShutdownClick = () => {
    this.setState({
      forceShutdownModalOpen: false,
    });
  };

  private handleConfirmForceShutdownClick = () => {
    this.props.forceShutdown();
  };

  private toggleResetTreasureHuntModal = () => {
    this.setState({
      resetTreasureHuntModalOpen: !this.state.resetTreasureHuntModalOpen,
    });
  };

  private handleConfirmResetTreasureHuntClick = () => {
    this.props.resetTreasureHunt();
    this.toggleResetTreasureHuntModal();
  };

  private handleEnabledChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        enabled: e.target.checked,
      },
    });
  };

  private handleMockOnlineChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        mockOnline: e.target.checked,
      },
    });
  };

  private handleTimeoutOrBanBroadcasterChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        timeoutOrBanBroadcaster: e.target.checked,
      },
    });
  };

  private handleTimeoutOrBanModeratorsChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        timeoutOrBanModerators: e.target.checked,
      },
    });
  };

  private handleTimeoutOrBanVipsChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        timeoutOrBanVips: e.target.checked,
      },
    });
  };

  private handlePuzzlesEnabledChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        puzzlesEnabled: e.target.checked,
      },
    });
  };

  private handleSendMessageOnEnterChange = (e: any) => {
    this.setState({
      sendMessageOnEnter: e.target.checked,
    });
  };

  private handleAutoStartEnabledChange = (e: any) => {
    this.setState({
      chatbotSettings: {
        ...this.state.chatbotSettings,
        autoStartEnabled: e.target.checked,
        enabled: e.target.checked ? false : this.state.chatbotSettings.enabled,
      },
    });
  };

  private handleUpdateSettings = () => {
    this.props.updateChatbotSettings(this.state.chatbotSettings);
  };

  private handleChatbotMessageChange = (e: any) => {
    this.setState({
      chatbotMessage: e.target.value,
    });
  };

  private handleChatbotMessageKeyDown = (e: any) => {
    if (e.key === "Enter" && this.state.sendMessageOnEnter) {
      e.preventDefault();

      if (this.state.chatbotMessage.length !== 0) {
        this.handleSendChatbotMessage();
      }
    }
  };

  private handleSendChatbotMessage = () => {
    this.props.sendChatbotMessage(this.state.chatbotMessage);
    this.setState({
      chatbotMessage: "",
    });

    if (this.messageTextareaRef != null) {
      this.messageTextareaRef.focus();
    }
  };
}

export default connect(
  (state: ApplicationState) => state.ciccaToolz,
  CiccaToolzStore.actionCreators
)(ChatbotManager as any);
