import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store/index";
import * as CiccaToolzStore from "../store/CiccaToolzStore";
import { TwitchStream, TwitchStreamParticipant } from "../models";
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import moment from "moment";
import "moment/locale/hu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  Colors,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

type ChattersPropsType = CiccaToolzStore.CiccaToolzState &
  typeof CiccaToolzStore.actionCreators &
  RouteComponentProps<{}>;

interface StreamMap {
  streamId: number;
  date: number;
}

interface ChattersState {
  date: Date | null;
  streamMap: StreamMap[];
  stream: TwitchStream | undefined;
  currentParticipant: TwitchStreamParticipant | null;
  currentExpandedUserIndex: number;
  userSearch: string;
  streamUsersLoadingStarted: boolean;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  Colors
);

class Chatters extends React.PureComponent<ChattersPropsType, ChattersState> {
  constructor(props: ChattersPropsType) {
    super(props);

    this.state = {
      date: null,
      streamMap: [],
      stream: undefined,
      currentParticipant: null,
      currentExpandedUserIndex: NaN,
      userSearch: "",
      streamUsersLoadingStarted: false,
    };
  }

  componentWillMount() {
    this.props.getStreams();
  }

  componentWillReceiveProps(nextProps: ChattersPropsType) {
    if (this.props.streams.length !== nextProps.streams.length) {
      this.setState({
        streamMap: nextProps.streams.map((x) => {
          return {
            streamId: x.id,
            date: new Date(
              x.startedAt.getFullYear(),
              x.startedAt.getMonth(),
              x.startedAt.getDate()
            ).valueOf(),
          };
        }),
      });
    }
  }

  public render() {
    const chattersLoaded =
      this.state.stream != null &&
      this.props.streamParticipants != null &&
      this.props.streamParticipants.participants.length > 0;

    const flattenedChartData = [];
    if (chattersLoaded && this.props.streamParticipants!.chartData.length > 0) {
      flattenedChartData.push(
        ...this.props.streamParticipants!.chartData[0].data
      );
    }

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "Stream nézők",
        },
      },
    };

    const chartData = {
      labels: flattenedChartData.map((x) => moment(x.dateTime).format("HH:mm")),
      datasets: chattersLoaded
        ? this.props.streamParticipants!.chartData.map((x, index) => {
            return {
              fill: true,
              label: x.category,
              data: x.data.map((x) => x.chatterCount || undefined),
            };
          })
        : [],
    };

    const detailsChartOptions = {
      responsive: true,
      indexAxis: "y" as const,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: `${
            this.state.currentParticipant != null
              ? this.state.currentParticipant.username
              : ""
          } nézés idővonal abszolút idő szerint`,
        },
      },
      scales: {
        y: {
          stacked: true,
          ticks: {
            display: false,
          },
        },
        x: {
          type: "time" as const,
          time: {
            displayFormats: {
              millisecond: "HH:mm",
              second: "HH:mm",
              minute: "HH:mm",
              hour: "HH:mm",
              day: "HH:mm",
              week: "HH:mm",
              month: "HH:mm",
              quarter: "HH:mm",
              year: "HH:mm",
            },
          },
          min:
            this.state.stream != null
              ? new Date(this.state.stream.startedAt).valueOf()
              : new Date().valueOf(),
          max:
            this.state.stream != null && this.state.stream.endedAt != null
              ? new Date(this.state.stream.endedAt).valueOf()
              : new Date().valueOf(),
        },
      },
    };

    const detailsChartData = {
      labels:
        this.state.currentParticipant != null
          ? [this.state.currentParticipant.username]
          : [],
      datasets:
        this.state.currentParticipant != null
          ? this.state.currentParticipant.sessions.map((x, index) => {
              return {
                label: `Nézés ${index + 1}`,
                data: [
                  [
                    new Date(x.startedAt),
                    x.endedAt != null
                      ? new Date(x.endedAt)
                      : this.state.stream!.endedAt != null
                      ? new Date(this.state.stream!.endedAt)
                      : new Date(),
                  ],
                ],
                tooltip: {
                  callbacks: {
                    label: (context: any) => {
                      return `${moment(context.dataset.data[0][0]).format(
                        "YYYY.MM.DD. HH:mm:ss"
                      )} - ${moment(context.dataset.data[0][1]).format(
                        "YYYY.MM.DD. HH:mm:ss"
                      )}`;
                    },
                  },
                },
              };
            })
          : [],
    };

    const filteredUsers = this.props.streamUsers.filter(
      (x) => x.username.toLowerCase().indexOf(this.state.userSearch) !== -1
    );

    const userChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "Stream részvételek",
        },
      },
    };

    const userChartData = isNaN(this.state.currentExpandedUserIndex)
      ? null
      : {
          labels: filteredUsers[
            this.state.currentExpandedUserIndex
          ].streams.map((y) => moment(y.stream.startedAt).format("YYYY.MM.DD")),
          datasets: [
            {
              label: "Részvétel %",
              data: filteredUsers[
                this.state.currentExpandedUserIndex
              ].streams.map((y) =>
                y.participationPercentage != null
                  ? y.participationPercentage
                  : 0
              ),
            },
          ],
        };

    return (
      <React.Fragment>
        <div className={`loader ${this.props.loading ? "show" : ""}`}>
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <h4 className="mb-3">Nézők</h4>

        <div className="tabbable full-width-tabs mt-4">
          <ul
            className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center"
            id="chatters-tab-list"
            role="tablist"
          >
            <li className="d-flex">
              <a
                className="nav-link active"
                id="chatters-by-stream-tab"
                data-toggle="pill"
                href="#chatters-by-stream"
                role="tab"
                aria-controls="by-stream"
                aria-selected="true"
              >
                Stream szerint
              </a>
            </li>
            <li className="d-flex">
              <a
                className="nav-link"
                id="chatters-by-user-tab"
                data-toggle="pill"
                href="#chatters-by-user"
                role="tab"
                aria-controls="by-user"
                aria-selected="false"
                onClick={() => {
                  if (!this.state.streamUsersLoadingStarted) {
                    this.props.getStreamUsers();
                  }

                  this.setState({
                    ...this.state,
                    streamUsersLoadingStarted: true,
                  });
                }}
              >
                Felhasználó szerint
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content card" id="chatters-tab-tab-content">
          <div
            className="tab-pane fade p-3 show active"
            id="chatters-by-stream"
            role="tabpanel"
            aria-labelledby="chatters-by-stream-tab"
          >
            <h5 className="mb-3">Streamek</h5>

            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex justify-content-start">
                <div className="form-group mb-4 datepicker-container">
                  <label htmlFor="inputDate">Dátum</label>
                  <Datetime
                    input={false}
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                    dateFormat="YYYY MMMM D"
                    timeFormat={false}
                    isValidDate={this.isValidDate}
                    utc={false}
                    value={
                      this.state.date == null ? undefined : this.state.date
                    }
                    onChange={this.handleDateChange}
                  />
                  {this.state.stream != null && (
                    <div className="mt-4">
                      <Button
                        color="primary"
                        className="w-100"
                        onClick={() =>
                          this.props.getChatMessageExportByStream(
                            this.state.stream!!.id
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={["fas", "comments"]}
                          className="fa-fw mr-1"
                        />{" "}
                        Chat export letöltése
                      </Button>
                    </div>
                  )}
                </div>

                {this.state.stream != null && (
                  <div className="ml-4">
                    <Card>
                      <CardBody>
                        <div className="mb-2">
                          <strong>A kiválasztott stream adatai</strong>
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={["fas", "hourglass-start"]}
                            className="fa-fw mr-1"
                          />{" "}
                          Kezdés:{" "}
                          {moment(this.state.stream.startedAt).format(
                            "YYYY.MM.DD HH:mm:ss"
                          )}
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={["fas", "hourglass-end"]}
                            className="fa-fw mr-1"
                          />{" "}
                          Zárás:{" "}
                          {this.state.stream.endedAt != null
                            ? moment(this.state.stream.endedAt).format(
                                "YYYY.MM.DD HH:mm:ss"
                              )
                            : "A stream még tart."}
                        </div>
                        {this.state.stream != null &&
                          this.state.stream.sessions.length > 0 && (
                            <div className="mt-3">
                              <strong>Kategóriák</strong>
                              {this.state.stream.sessions.map(
                                (streamSession, index) => {
                                  return (
                                    <Card key={index}>
                                      <CardBody>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={["fas", "gamepad"]}
                                            className="fa-fw mr-1"
                                          />{" "}
                                          Kategória: {streamSession.category}
                                        </div>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={["fas", "hourglass-start"]}
                                            className="fa-fw mr-1"
                                          />{" "}
                                          Kezdés:{" "}
                                          {moment(
                                            streamSession.startedAt
                                          ).format("YYYY.MM.DD HH:mm:ss")}
                                        </div>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={["fas", "hourglass-end"]}
                                            className="fa-fw mr-1"
                                          />{" "}
                                          Zárás:{" "}
                                          {streamSession.endedAt != null
                                            ? moment(
                                                streamSession.endedAt
                                              ).format("YYYY.MM.DD HH:mm:ss")
                                            : "A stream még tart."}
                                        </div>
                                      </CardBody>
                                    </Card>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </CardBody>
                    </Card>
                  </div>
                )}
              </div>
              <div className="w-50">
                {chattersLoaded && this.state.stream != null && (
                  <Line options={chartOptions} data={chartData} />
                )}
              </div>
            </div>

            <div className="mb-4">
              {chattersLoaded && (
                <React.Fragment>
                  <h6>
                    Stream nézők (
                    {this.props.streamParticipants!.participants.length})
                  </h6>
                  <ul className="list-group">
                    {this.props.streamParticipants!.participants.map(
                      (participant, index) => {
                        return (
                          <li
                            key={`stream-participant-${index}`}
                            className="list-group-item d-flex justify-content-between cursor-pointer"
                            onClick={() =>
                              this.handleParticipantClick(participant)
                            }
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={["fas", "user"]}
                                className="fa-fw mr-1"
                              />{" "}
                              {participant.username}
                            </div>
                            <div className="stream-participation-percentage-container">
                              <FontAwesomeIcon
                                icon={["fas", "user-clock"]}
                                className="fa-fw mr-1"
                              />{" "}
                              {participant.participationPercentage != null &&
                                `${Math.round(
                                  participant.participationPercentage
                                )}%`}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </React.Fragment>
              )}
            </div>
            {!chattersLoaded && (
              <h6>Válassz egy stream időpontot, hogy megnézd a nézőket.</h6>
            )}
          </div>
          <div
            className="tab-pane fade p-3"
            id="chatters-by-user"
            role="tabpanel"
            aria-labelledby="chatters-by-user-tab"
          >
            <h5 className="mb-3">Felhasználók</h5>
            <div className="form-group mr-3 mb-3 search-input-container">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder="Keresés felhasználónévre..."
                value={this.state.userSearch}
                onChange={this.handleUserSearchChange}
              />
            </div>
            {this.props.streamUsers.length === 0 && <div>Betöltés...</div>}
            {filteredUsers.map((streamUser, index) => {
              return (
                <Card className="p-3 my-2" key={index}>
                  <h6
                    role="button"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        currentExpandedUserIndex:
                          this.state.currentExpandedUserIndex === index
                            ? NaN
                            : index,
                      });
                    }}
                  >
                    {streamUser.username}
                  </h6>
                  {this.state.currentExpandedUserIndex === index && (
                    <div>
                      <div className="d-flex justify-content-center">
                        <Button
                          color="primary"
                          className="w-25"
                          onClick={() =>
                            this.props.getChatMessageExportByUsername(
                              streamUser.username
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={["fas", "comments"]}
                            className="fa-fw mr-1"
                          />{" "}
                          Chat üzenetek letöltése
                        </Button>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <div className="w-50">
                          <Bar
                            options={userChartOptions}
                            data={userChartData!}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              );
            })}
          </div>
        </div>

        {this.state.stream != null && (
          <Modal
            size="lg"
            isOpen={
              this.state.currentParticipant != null &&
              this.props.streamParticipantSessions.length > 0
            }
            toggle={this.toggleParticipantModal}
            className="modal-dialog-centered"
          >
            <ModalHeader toggle={this.toggleParticipantModal}>
              {this.state.currentParticipant != null &&
                this.state.currentParticipant.username}{" "}
              nézés részletei
            </ModalHeader>
            <ModalBody>
              <div className="mt-2 mb-2">
                <Bar options={detailsChartOptions} data={detailsChartData} />
              </div>
              <ul className="list-group">
                {this.props.streamParticipantSessions.map((session, index) => {
                  return (
                    <li
                      key={`stream-participant-session-${index}`}
                      className="list-group-item"
                    >
                      <div>
                        <h6>Abszolút idő szerint</h6>
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <FontAwesomeIcon
                              icon={["fas", "hourglass-start"]}
                              className="fa-fw mr-1"
                            />{" "}
                            {moment(session.startedAt).format("HH:mm:ss")}
                          </div>
                          <div>
                            {session.endedAt != null ? (
                              <React.Fragment>
                                <FontAwesomeIcon
                                  icon={["fas", "hourglass-end"]}
                                  className="fa-fw mr-1"
                                />{" "}
                                {moment(session.endedAt).format("HH:mm:ss")}
                              </React.Fragment>
                            ) : (
                              "A nézés még tart."
                            )}
                          </div>
                        </div>
                        <h6>Stream kezdetétől számítva</h6>
                        <div className="d-flex justify-content-between">
                          <div>
                            <FontAwesomeIcon
                              icon={["fas", "hourglass-start"]}
                              className="fa-fw mr-1"
                            />{" "}
                            {moment
                              .utc(
                                moment(session.startedAt).diff(
                                  this.state.stream!!.startedAt
                                )
                              )
                              .format("HH:mm:ss")}
                          </div>
                          <div>
                            {session.endedAt != null ? (
                              <React.Fragment>
                                <FontAwesomeIcon
                                  icon={["fas", "hourglass-end"]}
                                  className="fa-fw mr-1"
                                />{" "}
                                {moment
                                  .utc(
                                    moment(session.endedAt).diff(
                                      this.state.stream!!.startedAt
                                    )
                                  )
                                  .format("HH:mm:ss")}
                              </React.Fragment>
                            ) : (
                              "A nézés még tart."
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </ModalBody>
          </Modal>
        )}
      </React.Fragment>
    );
  }

  private handleUserSearchChange = (e: any) => {
    this.setState({
      currentExpandedUserIndex: NaN,
      userSearch: e.target.value,
    });
  };

  private handleParticipantClick = (participant: TwitchStreamParticipant) => {
    this.props.getStreamParticipantSessions(
      this.state.stream!!.id,
      participant.id
    );

    this.setState({
      currentParticipant: participant,
    });
  };

  private toggleParticipantModal = () => {
    this.setState({
      currentParticipant: null,
    });

    this.props.clearStreamParticipantSessions();
  };

  private handleDateChange = (date: any) => {
    this.setState({
      date: date,
    });

    const selectedDateValue = date.toDate().valueOf();
    const stream = this.state.streamMap.find(
      (x) => x.date === selectedDateValue
    );

    if (stream != null) {
      this.setState({
        stream: this.props.streams.find((x) => x.id === stream.streamId),
      });

      this.props.getStreamParticipants(stream.streamId);
    }
  };

  private isValidDate = (currentDate: moment.Moment) => {
    return (
      this.state.streamMap.filter((x) => {
        return x.date === currentDate.toDate().valueOf();
      }).length > 0
    );
  };
}

export default connect(
  (state: ApplicationState) => state.ciccaToolz,
  CiccaToolzStore.actionCreators
)(Chatters as any);
